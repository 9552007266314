export default {
  data() {
    return {
      classes: [
        '-bottom-0',
        '-bottom-05',
        '-bottom-1',
        '-bottom-1/2',
        '-bottom-1/3',
        '-bottom-1/4',
        '-bottom-10',
        '-bottom-11',
        '-bottom-12',
        '-bottom-14',
        '-bottom-15',
        '-bottom-16',
        '-bottom-2',
        '-bottom-2/3',
        '-bottom-2/4',
        '-bottom-20',
        '-bottom-24',
        '-bottom-25',
        '-bottom-28',
        '-bottom-3',
        '-bottom-3/4',
        '-bottom-32',
        '-bottom-35',
        '-bottom-36',
        '-bottom-4',
        '-bottom-40',
        '-bottom-44',
        '-bottom-48',
        '-bottom-5',
        '-bottom-52',
        '-bottom-56',
        '-bottom-6',
        '-bottom-60',
        '-bottom-64',
        '-bottom-7',
        '-bottom-72',
        '-bottom-8',
        '-bottom-80',
        '-bottom-9',
        '-bottom-96',
        '-bottom-full',
        '-bottom-px',
        '-inset-0',
        '-inset-05',
        '-inset-1',
        '-inset-1/2',
        '-inset-1/3',
        '-inset-1/4',
        '-inset-10',
        '-inset-11',
        '-inset-12',
        '-inset-14',
        '-inset-15',
        '-inset-16',
        '-inset-2',
        '-inset-2/3',
        '-inset-2/4',
        '-inset-20',
        '-inset-24',
        '-inset-25',
        '-inset-28',
        '-inset-3',
        '-inset-3/4',
        '-inset-32',
        '-inset-35',
        '-inset-36',
        '-inset-4',
        '-inset-40',
        '-inset-44',
        '-inset-48',
        '-inset-5',
        '-inset-52',
        '-inset-56',
        '-inset-6',
        '-inset-60',
        '-inset-64',
        '-inset-7',
        '-inset-72',
        '-inset-8',
        '-inset-80',
        '-inset-9',
        '-inset-96',
        '-inset-full',
        '-inset-px',
        '-inset-x-0',
        '-inset-x-05',
        '-inset-x-1',
        '-inset-x-1/2',
        '-inset-x-1/3',
        '-inset-x-1/4',
        '-inset-x-10',
        '-inset-x-11',
        '-inset-x-12',
        '-inset-x-14',
        '-inset-x-15',
        '-inset-x-16',
        '-inset-x-2',
        '-inset-x-2/3',
        '-inset-x-2/4',
        '-inset-x-20',
        '-inset-x-24',
        '-inset-x-25',
        '-inset-x-28',
        '-inset-x-3',
        '-inset-x-3/4',
        '-inset-x-32',
        '-inset-x-35',
        '-inset-x-36',
        '-inset-x-4',
        '-inset-x-40',
        '-inset-x-44',
        '-inset-x-48',
        '-inset-x-5',
        '-inset-x-52',
        '-inset-x-56',
        '-inset-x-6',
        '-inset-x-60',
        '-inset-x-64',
        '-inset-x-7',
        '-inset-x-72',
        '-inset-x-8',
        '-inset-x-80',
        '-inset-x-9',
        '-inset-x-96',
        '-inset-x-full',
        '-inset-x-px',
        '-inset-y-0',
        '-inset-y-05',
        '-inset-y-1',
        '-inset-y-1/2',
        '-inset-y-1/3',
        '-inset-y-1/4',
        '-inset-y-10',
        '-inset-y-11',
        '-inset-y-12',
        '-inset-y-14',
        '-inset-y-15',
        '-inset-y-16',
        '-inset-y-2',
        '-inset-y-2/3',
        '-inset-y-2/4',
        '-inset-y-20',
        '-inset-y-24',
        '-inset-y-25',
        '-inset-y-28',
        '-inset-y-3',
        '-inset-y-3/4',
        '-inset-y-32',
        '-inset-y-35',
        '-inset-y-36',
        '-inset-y-4',
        '-inset-y-40',
        '-inset-y-44',
        '-inset-y-48',
        '-inset-y-5',
        '-inset-y-52',
        '-inset-y-56',
        '-inset-y-6',
        '-inset-y-60',
        '-inset-y-64',
        '-inset-y-7',
        '-inset-y-72',
        '-inset-y-8',
        '-inset-y-80',
        '-inset-y-9',
        '-inset-y-96',
        '-inset-y-full',
        '-inset-y-px',
        '-left-0',
        '-left-05',
        '-left-1',
        '-left-1/2',
        '-left-1/3',
        '-left-1/4',
        '-left-10',
        '-left-11',
        '-left-12',
        '-left-14',
        '-left-15',
        '-left-16',
        '-left-2',
        '-left-2/3',
        '-left-2/4',
        '-left-20',
        '-left-24',
        '-left-25',
        '-left-28',
        '-left-3',
        '-left-3/4',
        '-left-32',
        '-left-35',
        '-left-36',
        '-left-4',
        '-left-40',
        '-left-44',
        '-left-48',
        '-left-5',
        '-left-52',
        '-left-56',
        '-left-6',
        '-left-60',
        '-left-64',
        '-left-7',
        '-left-72',
        '-left-8',
        '-left-80',
        '-left-9',
        '-left-96',
        '-left-full',
        '-left-px',
        '-m-0',
        '-m-05',
        '-m-1',
        '-m-1/12',
        '-m-1/2',
        '-m-1/3',
        '-m-1/4',
        '-m-1/5',
        '-m-1/6',
        '-m-10',
        '-m-10/12',
        '-m-11',
        '-m-11/12',
        '-m-12',
        '-m-13',
        '-m-14',
        '-m-15',
        '-m-16',
        '-m-2',
        '-m-2/12',
        '-m-2/3',
        '-m-2/4',
        '-m-2/5',
        '-m-2/6',
        '-m-20',
        '-m-24',
        '-m-25',
        '-m-28',
        '-m-3',
        '-m-3/12',
        '-m-3/4',
        '-m-3/5',
        '-m-3/6',
        '-m-32',
        '-m-35',
        '-m-36',
        '-m-4',
        '-m-4/12',
        '-m-4/5',
        '-m-4/6',
        '-m-40',
        '-m-44',
        '-m-48',
        '-m-5',
        '-m-5/12',
        '-m-5/6',
        '-m-52',
        '-m-56',
        '-m-6',
        '-m-6/12',
        '-m-60',
        '-m-64',
        '-m-7',
        '-m-7/12',
        '-m-72',
        '-m-8',
        '-m-8/12',
        '-m-80',
        '-m-9',
        '-m-9/12',
        '-m-96',
        '-m-full',
        '-m-px',
        '-mb-0',
        '-mb-05',
        '-mb-1',
        '-mb-1/12',
        '-mb-1/2',
        '-mb-1/3',
        '-mb-1/4',
        '-mb-1/5',
        '-mb-1/6',
        '-mb-10',
        '-mb-10/12',
        '-mb-11',
        '-mb-11/12',
        '-mb-12',
        '-mb-13',
        '-mb-14',
        '-mb-15',
        '-mb-16',
        '-mb-2',
        '-mb-2/12',
        '-mb-2/3',
        '-mb-2/4',
        '-mb-2/5',
        '-mb-2/6',
        '-mb-20',
        '-mb-24',
        '-mb-25',
        '-mb-28',
        '-mb-3',
        '-mb-3/12',
        '-mb-3/4',
        '-mb-3/5',
        '-mb-3/6',
        '-mb-32',
        '-mb-35',
        '-mb-36',
        '-mb-4',
        '-mb-4/12',
        '-mb-4/5',
        '-mb-4/6',
        '-mb-40',
        '-mb-44',
        '-mb-48',
        '-mb-5',
        '-mb-5/12',
        '-mb-5/6',
        '-mb-52',
        '-mb-56',
        '-mb-6',
        '-mb-6/12',
        '-mb-60',
        '-mb-64',
        '-mb-7',
        '-mb-7/12',
        '-mb-72',
        '-mb-8',
        '-mb-8/12',
        '-mb-80',
        '-mb-9',
        '-mb-9/12',
        '-mb-96',
        '-mb-full',
        '-mb-px',
        '-ml-0',
        '-ml-05',
        '-ml-1',
        '-ml-1/12',
        '-ml-1/2',
        '-ml-1/3',
        '-ml-1/4',
        '-ml-1/5',
        '-ml-1/6',
        '-ml-10',
        '-ml-10/12',
        '-ml-11',
        '-ml-11/12',
        '-ml-12',
        '-ml-13',
        '-ml-14',
        '-ml-15',
        '-ml-16',
        '-ml-2',
        '-ml-2/12',
        '-ml-2/3',
        '-ml-2/4',
        '-ml-2/5',
        '-ml-2/6',
        '-ml-20',
        '-ml-24',
        '-ml-25',
        '-ml-28',
        '-ml-3',
        '-ml-3/12',
        '-ml-3/4',
        '-ml-3/5',
        '-ml-3/6',
        '-ml-32',
        '-ml-35',
        '-ml-36',
        '-ml-4',
        '-ml-4/12',
        '-ml-4/5',
        '-ml-4/6',
        '-ml-40',
        '-ml-44',
        '-ml-48',
        '-ml-5',
        '-ml-5/12',
        '-ml-5/6',
        '-ml-52',
        '-ml-56',
        '-ml-6',
        '-ml-6/12',
        '-ml-60',
        '-ml-64',
        '-ml-7',
        '-ml-7/12',
        '-ml-72',
        '-ml-8',
        '-ml-8/12',
        '-ml-80',
        '-ml-9',
        '-ml-9/12',
        '-ml-96',
        '-ml-full',
        '-ml-px',
        '-mr-0',
        '-mr-05',
        '-mr-1',
        '-mr-1/12',
        '-mr-1/2',
        '-mr-1/3',
        '-mr-1/4',
        '-mr-1/5',
        '-mr-1/6',
        '-mr-10',
        '-mr-10/12',
        '-mr-11',
        '-mr-11/12',
        '-mr-12',
        '-mr-13',
        '-mr-14',
        '-mr-15',
        '-mr-16',
        '-mr-2',
        '-mr-2/12',
        '-mr-2/3',
        '-mr-2/4',
        '-mr-2/5',
        '-mr-2/6',
        '-mr-20',
        '-mr-24',
        '-mr-25',
        '-mr-28',
        '-mr-3',
        '-mr-3/12',
        '-mr-3/4',
        '-mr-3/5',
        '-mr-3/6',
        '-mr-32',
        '-mr-35',
        '-mr-36',
        '-mr-4',
        '-mr-4/12',
        '-mr-4/5',
        '-mr-4/6',
        '-mr-40',
        '-mr-44',
        '-mr-48',
        '-mr-5',
        '-mr-5/12',
        '-mr-5/6',
        '-mr-52',
        '-mr-56',
        '-mr-6',
        '-mr-6/12',
        '-mr-60',
        '-mr-64',
        '-mr-7',
        '-mr-7/12',
        '-mr-72',
        '-mr-8',
        '-mr-8/12',
        '-mr-80',
        '-mr-9',
        '-mr-9/12',
        '-mr-96',
        '-mr-full',
        '-mr-px',
        '-mt-0',
        '-mt-05',
        '-mt-1',
        '-mt-1/12',
        '-mt-1/2',
        '-mt-1/3',
        '-mt-1/4',
        '-mt-1/5',
        '-mt-1/6',
        '-mt-10',
        '-mt-10/12',
        '-mt-11',
        '-mt-11/12',
        '-mt-12',
        '-mt-13',
        '-mt-14',
        '-mt-15',
        '-mt-16',
        '-mt-2',
        '-mt-2/12',
        '-mt-2/3',
        '-mt-2/4',
        '-mt-2/5',
        '-mt-2/6',
        '-mt-20',
        '-mt-24',
        '-mt-25',
        '-mt-28',
        '-mt-3',
        '-mt-3/12',
        '-mt-3/4',
        '-mt-3/5',
        '-mt-3/6',
        '-mt-32',
        '-mt-35',
        '-mt-36',
        '-mt-4',
        '-mt-4/12',
        '-mt-4/5',
        '-mt-4/6',
        '-mt-40',
        '-mt-44',
        '-mt-48',
        '-mt-5',
        '-mt-5/12',
        '-mt-5/6',
        '-mt-52',
        '-mt-56',
        '-mt-6',
        '-mt-6/12',
        '-mt-60',
        '-mt-64',
        '-mt-7',
        '-mt-7/12',
        '-mt-72',
        '-mt-8',
        '-mt-8/12',
        '-mt-80',
        '-mt-9',
        '-mt-9/12',
        '-mt-96',
        '-mt-full',
        '-mt-px',
        '-mx-0',
        '-mx-05',
        '-mx-1',
        '-mx-1/12',
        '-mx-1/2',
        '-mx-1/3',
        '-mx-1/4',
        '-mx-1/5',
        '-mx-1/6',
        '-mx-10',
        '-mx-10/12',
        '-mx-11',
        '-mx-11/12',
        '-mx-12',
        '-mx-13',
        '-mx-14',
        '-mx-15',
        '-mx-16',
        '-mx-2',
        '-mx-2/12',
        '-mx-2/3',
        '-mx-2/4',
        '-mx-2/5',
        '-mx-2/6',
        '-mx-20',
        '-mx-24',
        '-mx-25',
        '-mx-28',
        '-mx-3',
        '-mx-3/12',
        '-mx-3/4',
        '-mx-3/5',
        '-mx-3/6',
        '-mx-32',
        '-mx-35',
        '-mx-36',
        '-mx-4',
        '-mx-4/12',
        '-mx-4/5',
        '-mx-4/6',
        '-mx-40',
        '-mx-44',
        '-mx-48',
        '-mx-5',
        '-mx-5/12',
        '-mx-5/6',
        '-mx-52',
        '-mx-56',
        '-mx-6',
        '-mx-6/12',
        '-mx-60',
        '-mx-64',
        '-mx-7',
        '-mx-7/12',
        '-mx-72',
        '-mx-8',
        '-mx-8/12',
        '-mx-80',
        '-mx-9',
        '-mx-9/12',
        '-mx-96',
        '-mx-full',
        '-mx-px',
        '-my-0',
        '-my-05',
        '-my-1',
        '-my-1/12',
        '-my-1/2',
        '-my-1/3',
        '-my-1/4',
        '-my-1/5',
        '-my-1/6',
        '-my-10',
        '-my-10/12',
        '-my-11',
        '-my-11/12',
        '-my-12',
        '-my-13',
        '-my-14',
        '-my-15',
        '-my-16',
        '-my-2',
        '-my-2/12',
        '-my-2/3',
        '-my-2/4',
        '-my-2/5',
        '-my-2/6',
        '-my-20',
        '-my-24',
        '-my-25',
        '-my-28',
        '-my-3',
        '-my-3/12',
        '-my-3/4',
        '-my-3/5',
        '-my-3/6',
        '-my-32',
        '-my-35',
        '-my-36',
        '-my-4',
        '-my-4/12',
        '-my-4/5',
        '-my-4/6',
        '-my-40',
        '-my-44',
        '-my-48',
        '-my-5',
        '-my-5/12',
        '-my-5/6',
        '-my-52',
        '-my-56',
        '-my-6',
        '-my-6/12',
        '-my-60',
        '-my-64',
        '-my-7',
        '-my-7/12',
        '-my-72',
        '-my-8',
        '-my-8/12',
        '-my-80',
        '-my-9',
        '-my-9/12',
        '-my-96',
        '-my-full',
        '-my-px',
        '-right-0',
        '-right-05',
        '-right-1',
        '-right-1/2',
        '-right-1/3',
        '-right-1/4',
        '-right-10',
        '-right-11',
        '-right-12',
        '-right-14',
        '-right-15',
        '-right-16',
        '-right-2',
        '-right-2/3',
        '-right-2/4',
        '-right-20',
        '-right-24',
        '-right-25',
        '-right-28',
        '-right-3',
        '-right-3/4',
        '-right-32',
        '-right-35',
        '-right-36',
        '-right-4',
        '-right-40',
        '-right-44',
        '-right-48',
        '-right-5',
        '-right-52',
        '-right-56',
        '-right-6',
        '-right-60',
        '-right-64',
        '-right-7',
        '-right-72',
        '-right-8',
        '-right-80',
        '-right-9',
        '-right-96',
        '-right-full',
        '-right-px',
        '-rotate-1',
        '-rotate-12',
        '-rotate-180',
        '-rotate-2',
        '-rotate-3',
        '-rotate-45',
        '-rotate-6',
        '-rotate-90',
        '-skew-x-1',
        '-skew-x-12',
        '-skew-x-2',
        '-skew-x-3',
        '-skew-x-6',
        '-skew-y-1',
        '-skew-y-12',
        '-skew-y-2',
        '-skew-y-3',
        '-skew-y-6',
        '-space-x-0',
        '-space-x-05',
        '-space-x-1',
        '-space-x-1/12',
        '-space-x-1/2',
        '-space-x-1/3',
        '-space-x-1/4',
        '-space-x-1/5',
        '-space-x-1/6',
        '-space-x-10',
        '-space-x-10/12',
        '-space-x-11',
        '-space-x-11/12',
        '-space-x-12',
        '-space-x-13',
        '-space-x-14',
        '-space-x-15',
        '-space-x-16',
        '-space-x-2',
        '-space-x-2/12',
        '-space-x-2/3',
        '-space-x-2/4',
        '-space-x-2/5',
        '-space-x-2/6',
        '-space-x-20',
        '-space-x-24',
        '-space-x-25',
        '-space-x-28',
        '-space-x-3',
        '-space-x-3/12',
        '-space-x-3/4',
        '-space-x-3/5',
        '-space-x-3/6',
        '-space-x-32',
        '-space-x-35',
        '-space-x-36',
        '-space-x-4',
        '-space-x-4/12',
        '-space-x-4/5',
        '-space-x-4/6',
        '-space-x-40',
        '-space-x-44',
        '-space-x-48',
        '-space-x-5',
        '-space-x-5/12',
        '-space-x-5/6',
        '-space-x-52',
        '-space-x-56',
        '-space-x-6',
        '-space-x-6/12',
        '-space-x-60',
        '-space-x-64',
        '-space-x-7',
        '-space-x-7/12',
        '-space-x-72',
        '-space-x-8',
        '-space-x-8/12',
        '-space-x-80',
        '-space-x-9',
        '-space-x-9/12',
        '-space-x-96',
        '-space-x-full',
        '-space-x-px',
        '-space-y-0',
        '-space-y-05',
        '-space-y-1',
        '-space-y-1/12',
        '-space-y-1/2',
        '-space-y-1/3',
        '-space-y-1/4',
        '-space-y-1/5',
        '-space-y-1/6',
        '-space-y-10',
        '-space-y-10/12',
        '-space-y-11',
        '-space-y-11/12',
        '-space-y-12',
        '-space-y-13',
        '-space-y-14',
        '-space-y-15',
        '-space-y-16',
        '-space-y-2',
        '-space-y-2/12',
        '-space-y-2/3',
        '-space-y-2/4',
        '-space-y-2/5',
        '-space-y-2/6',
        '-space-y-20',
        '-space-y-24',
        '-space-y-25',
        '-space-y-28',
        '-space-y-3',
        '-space-y-3/12',
        '-space-y-3/4',
        '-space-y-3/5',
        '-space-y-3/6',
        '-space-y-32',
        '-space-y-35',
        '-space-y-36',
        '-space-y-4',
        '-space-y-4/12',
        '-space-y-4/5',
        '-space-y-4/6',
        '-space-y-40',
        '-space-y-44',
        '-space-y-48',
        '-space-y-5',
        '-space-y-5/12',
        '-space-y-5/6',
        '-space-y-52',
        '-space-y-56',
        '-space-y-6',
        '-space-y-6/12',
        '-space-y-60',
        '-space-y-64',
        '-space-y-7',
        '-space-y-7/12',
        '-space-y-72',
        '-space-y-8',
        '-space-y-8/12',
        '-space-y-80',
        '-space-y-9',
        '-space-y-9/12',
        '-space-y-96',
        '-space-y-full',
        '-space-y-px',
        '-top-0',
        '-top-05',
        '-top-1',
        '-top-1/2',
        '-top-1/3',
        '-top-1/4',
        '-top-10',
        '-top-11',
        '-top-12',
        '-top-14',
        '-top-15',
        '-top-16',
        '-top-2',
        '-top-2/3',
        '-top-2/4',
        '-top-20',
        '-top-24',
        '-top-25',
        '-top-28',
        '-top-3',
        '-top-3/4',
        '-top-32',
        '-top-35',
        '-top-36',
        '-top-4',
        '-top-40',
        '-top-44',
        '-top-48',
        '-top-5',
        '-top-52',
        '-top-56',
        '-top-6',
        '-top-60',
        '-top-64',
        '-top-7',
        '-top-72',
        '-top-8',
        '-top-80',
        '-top-9',
        '-top-96',
        '-top-full',
        '-top-px',
        '-translate-x-0',
        '-translate-x-05',
        '-translate-x-1',
        '-translate-x-1/12',
        '-translate-x-1/2',
        '-translate-x-1/3',
        '-translate-x-1/4',
        '-translate-x-1/5',
        '-translate-x-1/6',
        '-translate-x-10',
        '-translate-x-10/12',
        '-translate-x-11',
        '-translate-x-11/12',
        '-translate-x-12',
        '-translate-x-13',
        '-translate-x-14',
        '-translate-x-15',
        '-translate-x-16',
        '-translate-x-2',
        '-translate-x-2/12',
        '-translate-x-2/3',
        '-translate-x-2/4',
        '-translate-x-2/5',
        '-translate-x-2/6',
        '-translate-x-20',
        '-translate-x-24',
        '-translate-x-25',
        '-translate-x-28',
        '-translate-x-3',
        '-translate-x-3/12',
        '-translate-x-3/4',
        '-translate-x-3/5',
        '-translate-x-3/6',
        '-translate-x-32',
        '-translate-x-35',
        '-translate-x-36',
        '-translate-x-4',
        '-translate-x-4/12',
        '-translate-x-4/5',
        '-translate-x-4/6',
        '-translate-x-40',
        '-translate-x-44',
        '-translate-x-48',
        '-translate-x-5',
        '-translate-x-5/12',
        '-translate-x-5/6',
        '-translate-x-52',
        '-translate-x-56',
        '-translate-x-6',
        '-translate-x-6/12',
        '-translate-x-60',
        '-translate-x-64',
        '-translate-x-7',
        '-translate-x-7/12',
        '-translate-x-72',
        '-translate-x-8',
        '-translate-x-8/12',
        '-translate-x-80',
        '-translate-x-9',
        '-translate-x-9/12',
        '-translate-x-96',
        '-translate-x-full',
        '-translate-x-px',
        '-translate-y-0',
        '-translate-y-05',
        '-translate-y-1',
        '-translate-y-1/12',
        '-translate-y-1/2',
        '-translate-y-1/3',
        '-translate-y-1/4',
        '-translate-y-1/5',
        '-translate-y-1/6',
        '-translate-y-10',
        '-translate-y-10/12',
        '-translate-y-11',
        '-translate-y-11/12',
        '-translate-y-12',
        '-translate-y-13',
        '-translate-y-14',
        '-translate-y-15',
        '-translate-y-16',
        '-translate-y-2',
        '-translate-y-2/12',
        '-translate-y-2/3',
        '-translate-y-2/4',
        '-translate-y-2/5',
        '-translate-y-2/6',
        '-translate-y-20',
        '-translate-y-24',
        '-translate-y-25',
        '-translate-y-28',
        '-translate-y-3',
        '-translate-y-3/12',
        '-translate-y-3/4',
        '-translate-y-3/5',
        '-translate-y-3/6',
        '-translate-y-32',
        '-translate-y-35',
        '-translate-y-36',
        '-translate-y-4',
        '-translate-y-4/12',
        '-translate-y-4/5',
        '-translate-y-4/6',
        '-translate-y-40',
        '-translate-y-44',
        '-translate-y-48',
        '-translate-y-5',
        '-translate-y-5/12',
        '-translate-y-5/6',
        '-translate-y-52',
        '-translate-y-56',
        '-translate-y-6',
        '-translate-y-6/12',
        '-translate-y-60',
        '-translate-y-64',
        '-translate-y-7',
        '-translate-y-7/12',
        '-translate-y-72',
        '-translate-y-8',
        '-translate-y-8/12',
        '-translate-y-80',
        '-translate-y-9',
        '-translate-y-9/12',
        '-translate-y-96',
        '-translate-y-full',
        '-translate-y-px',
        'absolute',
        'align-baseline',
        'align-bottom',
        'align-middle',
        'align-text-bottom',
        'align-text-top',
        'align-top',
        'animate-bounce',
        'animate-none',
        'animate-ping',
        'animate-pulse',
        'animate-spin',
        'antialiased',
        'appearance-none',
        'auto-cols-auto',
        'auto-cols-fr',
        'auto-cols-max',
        'auto-cols-min',
        'auto-rows-auto',
        'auto-rows-fr',
        'auto-rows-max',
        'auto-rows-min',
        'bg-auto',
        'bg-black',
        'bg-blue-100',
        'bg-blue-200',
        'bg-blue-300',
        'bg-blue-400',
        'bg-blue-50',
        'bg-blue-500',
        'bg-blue-600',
        'bg-blue-700',
        'bg-blue-800',
        'bg-blue-900',
        'bg-bottom',
        'bg-center',
        'bg-clip-border',
        'bg-clip-content',
        'bg-clip-padding',
        'bg-clip-text',
        'bg-contain',
        'bg-cool-gray-100',
        'bg-cool-gray-200',
        'bg-cool-gray-300',
        'bg-cool-gray-400',
        'bg-cool-gray-50',
        'bg-cool-gray-500',
        'bg-cool-gray-600',
        'bg-cool-gray-700',
        'bg-cool-gray-800',
        'bg-cool-gray-900',
        'bg-cover',
        'bg-current',
        'bg-fixed',
        'bg-gradient-to-b',
        'bg-gradient-to-bl',
        'bg-gradient-to-br',
        'bg-gradient-to-l',
        'bg-gradient-to-r',
        'bg-gradient-to-t',
        'bg-gradient-to-tl',
        'bg-gradient-to-tr',
        'bg-gray-100',
        'bg-gray-200',
        'bg-gray-300',
        'bg-gray-400',
        'bg-gray-50',
        'bg-gray-500',
        'bg-gray-600',
        'bg-gray-700',
        'bg-gray-800',
        'bg-gray-900',
        'bg-green-100',
        'bg-green-200',
        'bg-green-300',
        'bg-green-400',
        'bg-green-50',
        'bg-green-500',
        'bg-green-600',
        'bg-green-700',
        'bg-green-800',
        'bg-green-900',
        'bg-indigo-100',
        'bg-indigo-200',
        'bg-indigo-300',
        'bg-indigo-400',
        'bg-indigo-50',
        'bg-indigo-500',
        'bg-indigo-600',
        'bg-indigo-700',
        'bg-indigo-800',
        'bg-indigo-900',
        'bg-left',
        'bg-left-bottom',
        'bg-left-top',
        'bg-local',
        'bg-no-repeat',
        'bg-none',
        'bg-opacity-0',
        'bg-opacity-10',
        'bg-opacity-100',
        'bg-opacity-20',
        'bg-opacity-25',
        'bg-opacity-30',
        'bg-opacity-40',
        'bg-opacity-5',
        'bg-opacity-50',
        'bg-opacity-60',
        'bg-opacity-70',
        'bg-opacity-75',
        'bg-opacity-80',
        'bg-opacity-90',
        'bg-opacity-95',
        'bg-orange-100',
        'bg-orange-200',
        'bg-orange-300',
        'bg-orange-400',
        'bg-orange-50',
        'bg-orange-500',
        'bg-orange-600',
        'bg-orange-700',
        'bg-orange-800',
        'bg-orange-900',
        'bg-pink-100',
        'bg-pink-200',
        'bg-pink-300',
        'bg-pink-400',
        'bg-pink-50',
        'bg-pink-500',
        'bg-pink-600',
        'bg-pink-700',
        'bg-pink-800',
        'bg-pink-900',
        'bg-purple-100',
        'bg-purple-200',
        'bg-purple-300',
        'bg-purple-400',
        'bg-purple-50',
        'bg-purple-500',
        'bg-purple-600',
        'bg-purple-700',
        'bg-purple-800',
        'bg-purple-900',
        'bg-red-100',
        'bg-red-200',
        'bg-red-300',
        'bg-red-400',
        'bg-red-50',
        'bg-red-500',
        'bg-red-600',
        'bg-red-700',
        'bg-red-800',
        'bg-red-900',
        'bg-repeat',
        'bg-repeat-round',
        'bg-repeat-space',
        'bg-repeat-x',
        'bg-repeat-y',
        'bg-right',
        'bg-right-bottom',
        'bg-right-top',
        'bg-scroll',
        'bg-teal-100',
        'bg-teal-200',
        'bg-teal-300',
        'bg-teal-400',
        'bg-teal-50',
        'bg-teal-500',
        'bg-teal-600',
        'bg-teal-700',
        'bg-teal-800',
        'bg-teal-900',
        'bg-top',
        'bg-transparent',
        'bg-white',
        'bg-yellow-100',
        'bg-yellow-200',
        'bg-yellow-300',
        'bg-yellow-400',
        'bg-yellow-50',
        'bg-yellow-500',
        'bg-yellow-600',
        'bg-yellow-700',
        'bg-yellow-800',
        'bg-yellow-900',
        'block',
        'border',
        'border-0',
        'border-2',
        'border-4',
        'border-8',
        'border-b',
        'border-b-0',
        'border-b-2',
        'border-b-4',
        'border-b-8',
        'border-black',
        'border-blue-100',
        'border-blue-200',
        'border-blue-300',
        'border-blue-400',
        'border-blue-50',
        'border-blue-500',
        'border-blue-600',
        'border-blue-700',
        'border-blue-800',
        'border-blue-900',
        'border-collapse',
        'border-cool-gray-100',
        'border-cool-gray-200',
        'border-cool-gray-300',
        'border-cool-gray-400',
        'border-cool-gray-50',
        'border-cool-gray-500',
        'border-cool-gray-600',
        'border-cool-gray-700',
        'border-cool-gray-800',
        'border-cool-gray-900',
        'border-current',
        'border-dashed',
        'border-dotted',
        'border-double',
        'border-gray-100',
        'border-gray-200',
        'border-gray-300',
        'border-gray-400',
        'border-gray-50',
        'border-gray-500',
        'border-gray-600',
        'border-gray-700',
        'border-gray-800',
        'border-gray-900',
        'border-green-100',
        'border-green-200',
        'border-green-300',
        'border-green-400',
        'border-green-50',
        'border-green-500',
        'border-green-600',
        'border-green-700',
        'border-green-800',
        'border-green-900',
        'border-indigo-100',
        'border-indigo-200',
        'border-indigo-300',
        'border-indigo-400',
        'border-indigo-50',
        'border-indigo-500',
        'border-indigo-600',
        'border-indigo-700',
        'border-indigo-800',
        'border-indigo-900',
        'border-l',
        'border-l-0',
        'border-l-2',
        'border-l-4',
        'border-l-8',
        'border-none',
        'border-opacity-0',
        'border-opacity-10',
        'border-opacity-100',
        'border-opacity-20',
        'border-opacity-25',
        'border-opacity-30',
        'border-opacity-40',
        'border-opacity-5',
        'border-opacity-50',
        'border-opacity-60',
        'border-opacity-70',
        'border-opacity-75',
        'border-opacity-80',
        'border-opacity-90',
        'border-opacity-95',
        'border-orange-100',
        'border-orange-200',
        'border-orange-300',
        'border-orange-400',
        'border-orange-50',
        'border-orange-500',
        'border-orange-600',
        'border-orange-700',
        'border-orange-800',
        'border-orange-900',
        'border-pink-100',
        'border-pink-200',
        'border-pink-300',
        'border-pink-400',
        'border-pink-50',
        'border-pink-500',
        'border-pink-600',
        'border-pink-700',
        'border-pink-800',
        'border-pink-900',
        'border-purple-100',
        'border-purple-200',
        'border-purple-300',
        'border-purple-400',
        'border-purple-50',
        'border-purple-500',
        'border-purple-600',
        'border-purple-700',
        'border-purple-800',
        'border-purple-900',
        'border-r',
        'border-r-0',
        'border-r-2',
        'border-r-4',
        'border-r-8',
        'border-red-100',
        'border-red-200',
        'border-red-300',
        'border-red-400',
        'border-red-50',
        'border-red-500',
        'border-red-600',
        'border-red-700',
        'border-red-800',
        'border-red-900',
        'border-separate',
        'border-solid',
        'border-t',
        'border-t-0',
        'border-t-2',
        'border-t-4',
        'border-t-8',
        'border-teal-100',
        'border-teal-200',
        'border-teal-300',
        'border-teal-400',
        'border-teal-50',
        'border-teal-500',
        'border-teal-600',
        'border-teal-700',
        'border-teal-800',
        'border-teal-900',
        'border-transparent',
        'border-white',
        'border-yellow-100',
        'border-yellow-200',
        'border-yellow-300',
        'border-yellow-400',
        'border-yellow-50',
        'border-yellow-500',
        'border-yellow-600',
        'border-yellow-700',
        'border-yellow-800',
        'border-yellow-900',
        'bottom-0',
        'bottom-05',
        'bottom-1',
        'bottom-1/12',
        'bottom-1/2',
        'bottom-1/3',
        'bottom-1/4',
        'bottom-1/5',
        'bottom-1/6',
        'bottom-10',
        'bottom-10/12',
        'bottom-11',
        'bottom-11/12',
        'bottom-12',
        'bottom-13',
        'bottom-14',
        'bottom-15',
        'bottom-16',
        'bottom-2',
        'bottom-2/12',
        'bottom-2/3',
        'bottom-2/4',
        'bottom-2/5',
        'bottom-2/6',
        'bottom-20',
        'bottom-24',
        'bottom-25',
        'bottom-28',
        'bottom-3',
        'bottom-3/12',
        'bottom-3/4',
        'bottom-3/5',
        'bottom-3/6',
        'bottom-32',
        'bottom-35',
        'bottom-36',
        'bottom-4',
        'bottom-4/12',
        'bottom-4/5',
        'bottom-4/6',
        'bottom-40',
        'bottom-44',
        'bottom-48',
        'bottom-5',
        'bottom-5/12',
        'bottom-5/6',
        'bottom-52',
        'bottom-56',
        'bottom-6',
        'bottom-6/12',
        'bottom-60',
        'bottom-64',
        'bottom-7',
        'bottom-7/12',
        'bottom-72',
        'bottom-8',
        'bottom-8/12',
        'bottom-80',
        'bottom-9',
        'bottom-9/12',
        'bottom-96',
        'bottom-auto',
        'bottom-full',
        'bottom-px',
        'box-border',
        'box-content',
        'break-all',
        'break-normal',
        'break-words',
        'capitalize',
        'clear-both',
        'clear-left',
        'clear-none',
        'clear-right',
        'clearfix',
        'col-auto',
        'col-end-1',
        'col-end-10',
        'col-end-11',
        'col-end-12',
        'col-end-13',
        'col-end-2',
        'col-end-3',
        'col-end-4',
        'col-end-5',
        'col-end-6',
        'col-end-7',
        'col-end-8',
        'col-end-9',
        'col-end-auto',
        'col-gap-0',
        'col-gap-05',
        'col-gap-1',
        'col-gap-1/12',
        'col-gap-1/2',
        'col-gap-1/3',
        'col-gap-1/4',
        'col-gap-1/5',
        'col-gap-1/6',
        'col-gap-10',
        'col-gap-10/12',
        'col-gap-11',
        'col-gap-11/12',
        'col-gap-12',
        'col-gap-13',
        'col-gap-14',
        'col-gap-15',
        'col-gap-16',
        'col-gap-2',
        'col-gap-2/12',
        'col-gap-2/3',
        'col-gap-2/4',
        'col-gap-2/5',
        'col-gap-2/6',
        'col-gap-20',
        'col-gap-24',
        'col-gap-25',
        'col-gap-28',
        'col-gap-3',
        'col-gap-3/12',
        'col-gap-3/4',
        'col-gap-3/5',
        'col-gap-3/6',
        'col-gap-32',
        'col-gap-35',
        'col-gap-36',
        'col-gap-4',
        'col-gap-4/12',
        'col-gap-4/5',
        'col-gap-4/6',
        'col-gap-40',
        'col-gap-44',
        'col-gap-48',
        'col-gap-5',
        'col-gap-5/12',
        'col-gap-5/6',
        'col-gap-52',
        'col-gap-56',
        'col-gap-6',
        'col-gap-6/12',
        'col-gap-60',
        'col-gap-64',
        'col-gap-7',
        'col-gap-7/12',
        'col-gap-72',
        'col-gap-8',
        'col-gap-8/12',
        'col-gap-80',
        'col-gap-9',
        'col-gap-9/12',
        'col-gap-96',
        'col-gap-full',
        'col-gap-px',
        'col-span-1',
        'col-span-10',
        'col-span-11',
        'col-span-12',
        'col-span-2',
        'col-span-3',
        'col-span-4',
        'col-span-5',
        'col-span-6',
        'col-span-7',
        'col-span-8',
        'col-span-9',
        'col-span-full',
        'col-start-1',
        'col-start-10',
        'col-start-11',
        'col-start-12',
        'col-start-13',
        'col-start-2',
        'col-start-3',
        'col-start-4',
        'col-start-5',
        'col-start-6',
        'col-start-7',
        'col-start-8',
        'col-start-9',
        'col-start-auto',
        'content-around',
        'content-between',
        'content-center',
        'content-end',
        'content-evenly',
        'content-start',
        'contents',
        'cursor-auto',
        'cursor-default',
        'cursor-move',
        'cursor-not-allowed',
        'cursor-pointer',
        'cursor-text',
        'cursor-wait',
        'delay-100',
        'delay-1000',
        'delay-150',
        'delay-200',
        'delay-300',
        'delay-500',
        'delay-700',
        'delay-75',
        'diagonal-fractions',
        'divide-black',
        'divide-blue-100',
        'divide-blue-200',
        'divide-blue-300',
        'divide-blue-400',
        'divide-blue-50',
        'divide-blue-500',
        'divide-blue-600',
        'divide-blue-700',
        'divide-blue-800',
        'divide-blue-900',
        'divide-cool-gray-100',
        'divide-cool-gray-200',
        'divide-cool-gray-300',
        'divide-cool-gray-400',
        'divide-cool-gray-50',
        'divide-cool-gray-500',
        'divide-cool-gray-600',
        'divide-cool-gray-700',
        'divide-cool-gray-800',
        'divide-cool-gray-900',
        'divide-current',
        'divide-dashed',
        'divide-dotted',
        'divide-double',
        'divide-gray-100',
        'divide-gray-200',
        'divide-gray-300',
        'divide-gray-400',
        'divide-gray-50',
        'divide-gray-500',
        'divide-gray-600',
        'divide-gray-700',
        'divide-gray-800',
        'divide-gray-900',
        'divide-green-100',
        'divide-green-200',
        'divide-green-300',
        'divide-green-400',
        'divide-green-50',
        'divide-green-500',
        'divide-green-600',
        'divide-green-700',
        'divide-green-800',
        'divide-green-900',
        'divide-indigo-100',
        'divide-indigo-200',
        'divide-indigo-300',
        'divide-indigo-400',
        'divide-indigo-50',
        'divide-indigo-500',
        'divide-indigo-600',
        'divide-indigo-700',
        'divide-indigo-800',
        'divide-indigo-900',
        'divide-none',
        'divide-opacity-0',
        'divide-opacity-10',
        'divide-opacity-100',
        'divide-opacity-20',
        'divide-opacity-25',
        'divide-opacity-30',
        'divide-opacity-40',
        'divide-opacity-5',
        'divide-opacity-50',
        'divide-opacity-60',
        'divide-opacity-70',
        'divide-opacity-75',
        'divide-opacity-80',
        'divide-opacity-90',
        'divide-opacity-95',
        'divide-orange-100',
        'divide-orange-200',
        'divide-orange-300',
        'divide-orange-400',
        'divide-orange-50',
        'divide-orange-500',
        'divide-orange-600',
        'divide-orange-700',
        'divide-orange-800',
        'divide-orange-900',
        'divide-pink-100',
        'divide-pink-200',
        'divide-pink-300',
        'divide-pink-400',
        'divide-pink-50',
        'divide-pink-500',
        'divide-pink-600',
        'divide-pink-700',
        'divide-pink-800',
        'divide-pink-900',
        'divide-purple-100',
        'divide-purple-200',
        'divide-purple-300',
        'divide-purple-400',
        'divide-purple-50',
        'divide-purple-500',
        'divide-purple-600',
        'divide-purple-700',
        'divide-purple-800',
        'divide-purple-900',
        'divide-red-100',
        'divide-red-200',
        'divide-red-300',
        'divide-red-400',
        'divide-red-50',
        'divide-red-500',
        'divide-red-600',
        'divide-red-700',
        'divide-red-800',
        'divide-red-900',
        'divide-solid',
        'divide-teal-100',
        'divide-teal-200',
        'divide-teal-300',
        'divide-teal-400',
        'divide-teal-50',
        'divide-teal-500',
        'divide-teal-600',
        'divide-teal-700',
        'divide-teal-800',
        'divide-teal-900',
        'divide-transparent',
        'divide-white',
        'divide-x',
        'divide-x-0',
        'divide-x-2',
        'divide-x-4',
        'divide-x-8',
        'divide-x-reverse',
        'divide-y',
        'divide-y-0',
        'divide-y-2',
        'divide-y-4',
        'divide-y-8',
        'divide-y-reverse',
        'divide-yellow-100',
        'divide-yellow-200',
        'divide-yellow-300',
        'divide-yellow-400',
        'divide-yellow-50',
        'divide-yellow-500',
        'divide-yellow-600',
        'divide-yellow-700',
        'divide-yellow-800',
        'divide-yellow-900',
        'duration-100',
        'duration-1000',
        'duration-150',
        'duration-200',
        'duration-300',
        'duration-500',
        'duration-700',
        'duration-75',
        'ease-in',
        'ease-in-out',
        'ease-linear',
        'ease-out',
        'fill-current',
        'fixed',
        'flex',
        'flex-1',
        'flex-auto',
        'flex-col',
        'flex-col-reverse',
        'flex-grow',
        'flex-grow-0',
        'flex-initial',
        'flex-no-wrap',
        'flex-none',
        'flex-nowrap',
        'flex-row',
        'flex-row-reverse',
        'flex-shrink',
        'flex-shrink-0',
        'flex-wrap',
        'flex-wrap-reverse',
        'float-left',
        'float-none',
        'float-right',
        'flow-root',
        'font-black',
        'font-bold',
        'font-extrabold',
        'font-extralight',
        'font-hairline',
        'font-light',
        'font-medium',
        'font-mono',
        'font-normal',
        'font-sans',
        'font-semibold',
        'font-serif',
        'font-thin',
        'form-checkbox',
        'form-input',
        'form-multiselect',
        'form-radio',
        'form-select',
        'form-textarea',
        'from-black',
        'from-blue-100',
        'from-blue-200',
        'from-blue-300',
        'from-blue-400',
        'from-blue-50',
        'from-blue-500',
        'from-blue-600',
        'from-blue-700',
        'from-blue-800',
        'from-blue-900',
        'from-cool-gray-100',
        'from-cool-gray-200',
        'from-cool-gray-300',
        'from-cool-gray-400',
        'from-cool-gray-50',
        'from-cool-gray-500',
        'from-cool-gray-600',
        'from-cool-gray-700',
        'from-cool-gray-800',
        'from-cool-gray-900',
        'from-current',
        'from-gray-100',
        'from-gray-200',
        'from-gray-300',
        'from-gray-400',
        'from-gray-50',
        'from-gray-500',
        'from-gray-600',
        'from-gray-700',
        'from-gray-800',
        'from-gray-900',
        'from-green-100',
        'from-green-200',
        'from-green-300',
        'from-green-400',
        'from-green-50',
        'from-green-500',
        'from-green-600',
        'from-green-700',
        'from-green-800',
        'from-green-900',
        'from-indigo-100',
        'from-indigo-200',
        'from-indigo-300',
        'from-indigo-400',
        'from-indigo-50',
        'from-indigo-500',
        'from-indigo-600',
        'from-indigo-700',
        'from-indigo-800',
        'from-indigo-900',
        'from-orange-100',
        'from-orange-200',
        'from-orange-300',
        'from-orange-400',
        'from-orange-50',
        'from-orange-500',
        'from-orange-600',
        'from-orange-700',
        'from-orange-800',
        'from-orange-900',
        'from-pink-100',
        'from-pink-200',
        'from-pink-300',
        'from-pink-400',
        'from-pink-50',
        'from-pink-500',
        'from-pink-600',
        'from-pink-700',
        'from-pink-800',
        'from-pink-900',
        'from-purple-100',
        'from-purple-200',
        'from-purple-300',
        'from-purple-400',
        'from-purple-50',
        'from-purple-500',
        'from-purple-600',
        'from-purple-700',
        'from-purple-800',
        'from-purple-900',
        'from-red-100',
        'from-red-200',
        'from-red-300',
        'from-red-400',
        'from-red-50',
        'from-red-500',
        'from-red-600',
        'from-red-700',
        'from-red-800',
        'from-red-900',
        'from-teal-100',
        'from-teal-200',
        'from-teal-300',
        'from-teal-400',
        'from-teal-50',
        'from-teal-500',
        'from-teal-600',
        'from-teal-700',
        'from-teal-800',
        'from-teal-900',
        'from-transparent',
        'from-white',
        'from-yellow-100',
        'from-yellow-200',
        'from-yellow-300',
        'from-yellow-400',
        'from-yellow-50',
        'from-yellow-500',
        'from-yellow-600',
        'from-yellow-700',
        'from-yellow-800',
        'from-yellow-900',
        'gap-0',
        'gap-05',
        'gap-1',
        'gap-1/12',
        'gap-1/2',
        'gap-1/3',
        'gap-1/4',
        'gap-1/5',
        'gap-1/6',
        'gap-10',
        'gap-10/12',
        'gap-11',
        'gap-11/12',
        'gap-12',
        'gap-13',
        'gap-14',
        'gap-15',
        'gap-16',
        'gap-2',
        'gap-2/12',
        'gap-2/3',
        'gap-2/4',
        'gap-2/5',
        'gap-2/6',
        'gap-20',
        'gap-24',
        'gap-25',
        'gap-28',
        'gap-3',
        'gap-3/12',
        'gap-3/4',
        'gap-3/5',
        'gap-3/6',
        'gap-32',
        'gap-35',
        'gap-36',
        'gap-4',
        'gap-4/12',
        'gap-4/5',
        'gap-4/6',
        'gap-40',
        'gap-44',
        'gap-48',
        'gap-5',
        'gap-5/12',
        'gap-5/6',
        'gap-52',
        'gap-56',
        'gap-6',
        'gap-6/12',
        'gap-60',
        'gap-64',
        'gap-7',
        'gap-7/12',
        'gap-72',
        'gap-8',
        'gap-8/12',
        'gap-80',
        'gap-9',
        'gap-9/12',
        'gap-96',
        'gap-full',
        'gap-px',
        'gap-x-0',
        'gap-x-05',
        'gap-x-1',
        'gap-x-1/12',
        'gap-x-1/2',
        'gap-x-1/3',
        'gap-x-1/4',
        'gap-x-1/5',
        'gap-x-1/6',
        'gap-x-10',
        'gap-x-10/12',
        'gap-x-11',
        'gap-x-11/12',
        'gap-x-12',
        'gap-x-13',
        'gap-x-14',
        'gap-x-15',
        'gap-x-16',
        'gap-x-2',
        'gap-x-2/12',
        'gap-x-2/3',
        'gap-x-2/4',
        'gap-x-2/5',
        'gap-x-2/6',
        'gap-x-20',
        'gap-x-24',
        'gap-x-25',
        'gap-x-28',
        'gap-x-3',
        'gap-x-3/12',
        'gap-x-3/4',
        'gap-x-3/5',
        'gap-x-3/6',
        'gap-x-32',
        'gap-x-35',
        'gap-x-36',
        'gap-x-4',
        'gap-x-4/12',
        'gap-x-4/5',
        'gap-x-4/6',
        'gap-x-40',
        'gap-x-44',
        'gap-x-48',
        'gap-x-5',
        'gap-x-5/12',
        'gap-x-5/6',
        'gap-x-52',
        'gap-x-56',
        'gap-x-6',
        'gap-x-6/12',
        'gap-x-60',
        'gap-x-64',
        'gap-x-7',
        'gap-x-7/12',
        'gap-x-72',
        'gap-x-8',
        'gap-x-8/12',
        'gap-x-80',
        'gap-x-9',
        'gap-x-9/12',
        'gap-x-96',
        'gap-x-full',
        'gap-x-px',
        'gap-y-0',
        'gap-y-05',
        'gap-y-1',
        'gap-y-1/12',
        'gap-y-1/2',
        'gap-y-1/3',
        'gap-y-1/4',
        'gap-y-1/5',
        'gap-y-1/6',
        'gap-y-10',
        'gap-y-10/12',
        'gap-y-11',
        'gap-y-11/12',
        'gap-y-12',
        'gap-y-13',
        'gap-y-14',
        'gap-y-15',
        'gap-y-16',
        'gap-y-2',
        'gap-y-2/12',
        'gap-y-2/3',
        'gap-y-2/4',
        'gap-y-2/5',
        'gap-y-2/6',
        'gap-y-20',
        'gap-y-24',
        'gap-y-25',
        'gap-y-28',
        'gap-y-3',
        'gap-y-3/12',
        'gap-y-3/4',
        'gap-y-3/5',
        'gap-y-3/6',
        'gap-y-32',
        'gap-y-35',
        'gap-y-36',
        'gap-y-4',
        'gap-y-4/12',
        'gap-y-4/5',
        'gap-y-4/6',
        'gap-y-40',
        'gap-y-44',
        'gap-y-48',
        'gap-y-5',
        'gap-y-5/12',
        'gap-y-5/6',
        'gap-y-52',
        'gap-y-56',
        'gap-y-6',
        'gap-y-6/12',
        'gap-y-60',
        'gap-y-64',
        'gap-y-7',
        'gap-y-7/12',
        'gap-y-72',
        'gap-y-8',
        'gap-y-8/12',
        'gap-y-80',
        'gap-y-9',
        'gap-y-9/12',
        'gap-y-96',
        'gap-y-full',
        'gap-y-px',
        'grid',
        'grid-cols-1',
        'grid-cols-10',
        'grid-cols-11',
        'grid-cols-12',
        'grid-cols-2',
        'grid-cols-3',
        'grid-cols-4',
        'grid-cols-5',
        'grid-cols-6',
        'grid-cols-7',
        'grid-cols-8',
        'grid-cols-9',
        'grid-cols-none',
        'grid-flow-col',
        'grid-flow-col-dense',
        'grid-flow-row',
        'grid-flow-row-dense',
        'grid-rows-1',
        'grid-rows-2',
        'grid-rows-3',
        'grid-rows-4',
        'grid-rows-5',
        'grid-rows-6',
        'grid-rows-none',
        'h-0',
        'h-05',
        'h-1',
        'h-1/12',
        'h-1/2',
        'h-1/3',
        'h-1/4',
        'h-1/5',
        'h-1/6',
        'h-10',
        'h-10/12',
        'h-11',
        'h-11/12',
        'h-12',
        'h-13',
        'h-14',
        'h-15',
        'h-16',
        'h-2',
        'h-2/12',
        'h-2/3',
        'h-2/4',
        'h-2/5',
        'h-2/6',
        'h-20',
        'h-24',
        'h-25',
        'h-28',
        'h-3',
        'h-3/12',
        'h-3/4',
        'h-3/5',
        'h-3/6',
        'h-32',
        'h-35',
        'h-36',
        'h-4',
        'h-4/12',
        'h-4/5',
        'h-4/6',
        'h-40',
        'h-44',
        'h-48',
        'h-5',
        'h-5/12',
        'h-5/6',
        'h-52',
        'h-56',
        'h-6',
        'h-6/12',
        'h-60',
        'h-64',
        'h-7',
        'h-7/12',
        'h-72',
        'h-8',
        'h-8/12',
        'h-80',
        'h-9',
        'h-9/12',
        'h-96',
        'h-auto',
        'h-full',
        'h-px',
        'h-screen',
        'hidden',
        'inline',
        'inline-block',
        'inline-flex',
        'inline-grid',
        'inset-0',
        'inset-05',
        'inset-1',
        'inset-1/12',
        'inset-1/2',
        'inset-1/3',
        'inset-1/4',
        'inset-1/5',
        'inset-1/6',
        'inset-10',
        'inset-10/12',
        'inset-11',
        'inset-11/12',
        'inset-12',
        'inset-13',
        'inset-14',
        'inset-15',
        'inset-16',
        'inset-2',
        'inset-2/12',
        'inset-2/3',
        'inset-2/4',
        'inset-2/5',
        'inset-2/6',
        'inset-20',
        'inset-24',
        'inset-25',
        'inset-28',
        'inset-3',
        'inset-3/12',
        'inset-3/4',
        'inset-3/5',
        'inset-3/6',
        'inset-32',
        'inset-35',
        'inset-36',
        'inset-4',
        'inset-4/12',
        'inset-4/5',
        'inset-4/6',
        'inset-40',
        'inset-44',
        'inset-48',
        'inset-5',
        'inset-5/12',
        'inset-5/6',
        'inset-52',
        'inset-56',
        'inset-6',
        'inset-6/12',
        'inset-60',
        'inset-64',
        'inset-7',
        'inset-7/12',
        'inset-72',
        'inset-8',
        'inset-8/12',
        'inset-80',
        'inset-9',
        'inset-9/12',
        'inset-96',
        'inset-auto',
        'inset-full',
        'inset-px',
        'inset-x-0',
        'inset-x-05',
        'inset-x-1',
        'inset-x-1/12',
        'inset-x-1/2',
        'inset-x-1/3',
        'inset-x-1/4',
        'inset-x-1/5',
        'inset-x-1/6',
        'inset-x-10',
        'inset-x-10/12',
        'inset-x-11',
        'inset-x-11/12',
        'inset-x-12',
        'inset-x-13',
        'inset-x-14',
        'inset-x-15',
        'inset-x-16',
        'inset-x-2',
        'inset-x-2/12',
        'inset-x-2/3',
        'inset-x-2/4',
        'inset-x-2/5',
        'inset-x-2/6',
        'inset-x-20',
        'inset-x-24',
        'inset-x-25',
        'inset-x-28',
        'inset-x-3',
        'inset-x-3/12',
        'inset-x-3/4',
        'inset-x-3/5',
        'inset-x-3/6',
        'inset-x-32',
        'inset-x-35',
        'inset-x-36',
        'inset-x-4',
        'inset-x-4/12',
        'inset-x-4/5',
        'inset-x-4/6',
        'inset-x-40',
        'inset-x-44',
        'inset-x-48',
        'inset-x-5',
        'inset-x-5/12',
        'inset-x-5/6',
        'inset-x-52',
        'inset-x-56',
        'inset-x-6',
        'inset-x-6/12',
        'inset-x-60',
        'inset-x-64',
        'inset-x-7',
        'inset-x-7/12',
        'inset-x-72',
        'inset-x-8',
        'inset-x-8/12',
        'inset-x-80',
        'inset-x-9',
        'inset-x-9/12',
        'inset-x-96',
        'inset-x-auto',
        'inset-x-full',
        'inset-x-px',
        'inset-y-0',
        'inset-y-05',
        'inset-y-1',
        'inset-y-1/12',
        'inset-y-1/2',
        'inset-y-1/3',
        'inset-y-1/4',
        'inset-y-1/5',
        'inset-y-1/6',
        'inset-y-10',
        'inset-y-10/12',
        'inset-y-11',
        'inset-y-11/12',
        'inset-y-12',
        'inset-y-13',
        'inset-y-14',
        'inset-y-15',
        'inset-y-16',
        'inset-y-2',
        'inset-y-2/12',
        'inset-y-2/3',
        'inset-y-2/4',
        'inset-y-2/5',
        'inset-y-2/6',
        'inset-y-20',
        'inset-y-24',
        'inset-y-25',
        'inset-y-28',
        'inset-y-3',
        'inset-y-3/12',
        'inset-y-3/4',
        'inset-y-3/5',
        'inset-y-3/6',
        'inset-y-32',
        'inset-y-35',
        'inset-y-36',
        'inset-y-4',
        'inset-y-4/12',
        'inset-y-4/5',
        'inset-y-4/6',
        'inset-y-40',
        'inset-y-44',
        'inset-y-48',
        'inset-y-5',
        'inset-y-5/12',
        'inset-y-5/6',
        'inset-y-52',
        'inset-y-56',
        'inset-y-6',
        'inset-y-6/12',
        'inset-y-60',
        'inset-y-64',
        'inset-y-7',
        'inset-y-7/12',
        'inset-y-72',
        'inset-y-8',
        'inset-y-8/12',
        'inset-y-80',
        'inset-y-9',
        'inset-y-9/12',
        'inset-y-96',
        'inset-y-auto',
        'inset-y-full',
        'inset-y-px',
        'invisible',
        'italic',
        'items-baseline',
        'items-center',
        'items-end',
        'items-start',
        'items-stretch',
        'justify-around',
        'justify-between',
        'justify-center',
        'justify-end',
        'justify-evenly',
        'justify-items-auto',
        'justify-items-center',
        'justify-items-end',
        'justify-items-start',
        'justify-items-stretch',
        'justify-self-auto',
        'justify-self-center',
        'justify-self-end',
        'justify-self-start',
        'justify-self-stretch',
        'justify-start',
        'leading-10',
        'leading-3',
        'leading-4',
        'leading-5',
        'leading-6',
        'leading-7',
        'leading-8',
        'leading-9',
        'leading-loose',
        'leading-none',
        'leading-normal',
        'leading-relaxed',
        'leading-snug',
        'leading-tight',
        'left-0',
        'left-05',
        'left-1',
        'left-1/12',
        'left-1/2',
        'left-1/3',
        'left-1/4',
        'left-1/5',
        'left-1/6',
        'left-10',
        'left-10/12',
        'left-11',
        'left-11/12',
        'left-12',
        'left-13',
        'left-14',
        'left-15',
        'left-16',
        'left-2',
        'left-2/12',
        'left-2/3',
        'left-2/4',
        'left-2/5',
        'left-2/6',
        'left-20',
        'left-24',
        'left-25',
        'left-28',
        'left-3',
        'left-3/12',
        'left-3/4',
        'left-3/5',
        'left-3/6',
        'left-32',
        'left-35',
        'left-36',
        'left-4',
        'left-4/12',
        'left-4/5',
        'left-4/6',
        'left-40',
        'left-44',
        'left-48',
        'left-5',
        'left-5/12',
        'left-5/6',
        'left-52',
        'left-56',
        'left-6',
        'left-6/12',
        'left-60',
        'left-64',
        'left-7',
        'left-7/12',
        'left-72',
        'left-8',
        'left-8/12',
        'left-80',
        'left-9',
        'left-9/12',
        'left-96',
        'left-auto',
        'left-full',
        'left-px',
        'line-through',
        'lining-nums',
        'list-decimal',
        'list-disc',
        'list-inside',
        'list-none',
        'list-outside',
        'lowercase',
        'm-0',
        'm-05',
        'm-1',
        'm-1/12',
        'm-1/2',
        'm-1/3',
        'm-1/4',
        'm-1/5',
        'm-1/6',
        'm-10',
        'm-10/12',
        'm-11',
        'm-11/12',
        'm-12',
        'm-13',
        'm-14',
        'm-15',
        'm-16',
        'm-2',
        'm-2/12',
        'm-2/3',
        'm-2/4',
        'm-2/5',
        'm-2/6',
        'm-20',
        'm-24',
        'm-25',
        'm-28',
        'm-3',
        'm-3/12',
        'm-3/4',
        'm-3/5',
        'm-3/6',
        'm-32',
        'm-35',
        'm-36',
        'm-4',
        'm-4/12',
        'm-4/5',
        'm-4/6',
        'm-40',
        'm-44',
        'm-48',
        'm-5',
        'm-5/12',
        'm-5/6',
        'm-52',
        'm-56',
        'm-6',
        'm-6/12',
        'm-60',
        'm-64',
        'm-7',
        'm-7/12',
        'm-72',
        'm-8',
        'm-8/12',
        'm-80',
        'm-9',
        'm-9/12',
        'm-96',
        'm-auto',
        'm-full',
        'm-px',
        'max-h-0',
        'max-h-05',
        'max-h-1',
        'max-h-1/12',
        'max-h-1/2',
        'max-h-1/3',
        'max-h-1/4',
        'max-h-1/5',
        'max-h-1/6',
        'max-h-10',
        'max-h-10/12',
        'max-h-11',
        'max-h-11/12',
        'max-h-12',
        'max-h-13',
        'max-h-14',
        'max-h-15',
        'max-h-16',
        'max-h-2',
        'max-h-2/12',
        'max-h-2/3',
        'max-h-2/4',
        'max-h-2/5',
        'max-h-2/6',
        'max-h-20',
        'max-h-24',
        'max-h-25',
        'max-h-28',
        'max-h-3',
        'max-h-3/12',
        'max-h-3/4',
        'max-h-3/5',
        'max-h-3/6',
        'max-h-32',
        'max-h-35',
        'max-h-36',
        'max-h-4',
        'max-h-4/12',
        'max-h-4/5',
        'max-h-4/6',
        'max-h-40',
        'max-h-44',
        'max-h-48',
        'max-h-5',
        'max-h-5/12',
        'max-h-5/6',
        'max-h-52',
        'max-h-56',
        'max-h-6',
        'max-h-6/12',
        'max-h-60',
        'max-h-64',
        'max-h-7',
        'max-h-7/12',
        'max-h-72',
        'max-h-8',
        'max-h-8/12',
        'max-h-80',
        'max-h-9',
        'max-h-9/12',
        'max-h-96',
        'max-h-full',
        'max-h-px',
        'max-h-screen',
        'max-w-0',
        'max-w-2xl',
        'max-w-3xl',
        'max-w-4xl',
        'max-w-5xl',
        'max-w-6xl',
        'max-w-7xl',
        'max-w-full',
        'max-w-lg',
        'max-w-max',
        'max-w-max-content',
        'max-w-md',
        'max-w-min',
        'max-w-min-content',
        'max-w-none',
        'max-w-prose',
        'max-w-screen-2xl',
        'max-w-screen-lg',
        'max-w-screen-md',
        'max-w-screen-sm',
        'max-w-screen-xl',
        'max-w-sm',
        'max-w-xl',
        'max-w-xs',
        'mb-0',
        'mb-05',
        'mb-1',
        'mb-1/12',
        'mb-1/2',
        'mb-1/3',
        'mb-1/4',
        'mb-1/5',
        'mb-1/6',
        'mb-10',
        'mb-10/12',
        'mb-11',
        'mb-11/12',
        'mb-12',
        'mb-13',
        'mb-14',
        'mb-15',
        'mb-16',
        'mb-2',
        'mb-2/12',
        'mb-2/3',
        'mb-2/4',
        'mb-2/5',
        'mb-2/6',
        'mb-20',
        'mb-24',
        'mb-25',
        'mb-28',
        'mb-3',
        'mb-3/12',
        'mb-3/4',
        'mb-3/5',
        'mb-3/6',
        'mb-32',
        'mb-35',
        'mb-36',
        'mb-4',
        'mb-4/12',
        'mb-4/5',
        'mb-4/6',
        'mb-40',
        'mb-44',
        'mb-48',
        'mb-5',
        'mb-5/12',
        'mb-5/6',
        'mb-52',
        'mb-56',
        'mb-6',
        'mb-6/12',
        'mb-60',
        'mb-64',
        'mb-7',
        'mb-7/12',
        'mb-72',
        'mb-8',
        'mb-8/12',
        'mb-80',
        'mb-9',
        'mb-9/12',
        'mb-96',
        'mb-auto',
        'mb-full',
        'mb-px',
        'min-h-0',
        'min-h-full',
        'min-h-screen',
        'min-w-0',
        'min-w-full',
        'min-w-max',
        'min-w-max-content',
        'min-w-min',
        'min-w-min-content',
        'ml-0',
        'ml-05',
        'ml-1',
        'ml-1/12',
        'ml-1/2',
        'ml-1/3',
        'ml-1/4',
        'ml-1/5',
        'ml-1/6',
        'ml-10',
        'ml-10/12',
        'ml-11',
        'ml-11/12',
        'ml-12',
        'ml-13',
        'ml-14',
        'ml-15',
        'ml-16',
        'ml-2',
        'ml-2/12',
        'ml-2/3',
        'ml-2/4',
        'ml-2/5',
        'ml-2/6',
        'ml-20',
        'ml-24',
        'ml-25',
        'ml-28',
        'ml-3',
        'ml-3/12',
        'ml-3/4',
        'ml-3/5',
        'ml-3/6',
        'ml-32',
        'ml-35',
        'ml-36',
        'ml-4',
        'ml-4/12',
        'ml-4/5',
        'ml-4/6',
        'ml-40',
        'ml-44',
        'ml-48',
        'ml-5',
        'ml-5/12',
        'ml-5/6',
        'ml-52',
        'ml-56',
        'ml-6',
        'ml-6/12',
        'ml-60',
        'ml-64',
        'ml-7',
        'ml-7/12',
        'ml-72',
        'ml-8',
        'ml-8/12',
        'ml-80',
        'ml-9',
        'ml-9/12',
        'ml-96',
        'ml-auto',
        'ml-full',
        'ml-px',
        'mr-0',
        'mr-05',
        'mr-1',
        'mr-1/12',
        'mr-1/2',
        'mr-1/3',
        'mr-1/4',
        'mr-1/5',
        'mr-1/6',
        'mr-10',
        'mr-10/12',
        'mr-11',
        'mr-11/12',
        'mr-12',
        'mr-13',
        'mr-14',
        'mr-15',
        'mr-16',
        'mr-2',
        'mr-2/12',
        'mr-2/3',
        'mr-2/4',
        'mr-2/5',
        'mr-2/6',
        'mr-20',
        'mr-24',
        'mr-25',
        'mr-28',
        'mr-3',
        'mr-3/12',
        'mr-3/4',
        'mr-3/5',
        'mr-3/6',
        'mr-32',
        'mr-35',
        'mr-36',
        'mr-4',
        'mr-4/12',
        'mr-4/5',
        'mr-4/6',
        'mr-40',
        'mr-44',
        'mr-48',
        'mr-5',
        'mr-5/12',
        'mr-5/6',
        'mr-52',
        'mr-56',
        'mr-6',
        'mr-6/12',
        'mr-60',
        'mr-64',
        'mr-7',
        'mr-7/12',
        'mr-72',
        'mr-8',
        'mr-8/12',
        'mr-80',
        'mr-9',
        'mr-9/12',
        'mr-96',
        'mr-auto',
        'mr-full',
        'mr-px',
        'mt-0',
        'mt-05',
        'mt-1',
        'mt-1/12',
        'mt-1/2',
        'mt-1/3',
        'mt-1/4',
        'mt-1/5',
        'mt-1/6',
        'mt-10',
        'mt-10/12',
        'mt-11',
        'mt-11/12',
        'mt-12',
        'mt-13',
        'mt-14',
        'mt-15',
        'mt-16',
        'mt-2',
        'mt-2/12',
        'mt-2/3',
        'mt-2/4',
        'mt-2/5',
        'mt-2/6',
        'mt-20',
        'mt-24',
        'mt-25',
        'mt-28',
        'mt-3',
        'mt-3/12',
        'mt-3/4',
        'mt-3/5',
        'mt-3/6',
        'mt-32',
        'mt-35',
        'mt-36',
        'mt-4',
        'mt-4/12',
        'mt-4/5',
        'mt-4/6',
        'mt-40',
        'mt-44',
        'mt-48',
        'mt-5',
        'mt-5/12',
        'mt-5/6',
        'mt-52',
        'mt-56',
        'mt-6',
        'mt-6/12',
        'mt-60',
        'mt-64',
        'mt-7',
        'mt-7/12',
        'mt-72',
        'mt-8',
        'mt-8/12',
        'mt-80',
        'mt-9',
        'mt-9/12',
        'mt-96',
        'mt-auto',
        'mt-full',
        'mt-px',
        'mx-0',
        'mx-05',
        'mx-1',
        'mx-1/12',
        'mx-1/2',
        'mx-1/3',
        'mx-1/4',
        'mx-1/5',
        'mx-1/6',
        'mx-10',
        'mx-10/12',
        'mx-11',
        'mx-11/12',
        'mx-12',
        'mx-13',
        'mx-14',
        'mx-15',
        'mx-16',
        'mx-2',
        'mx-2/12',
        'mx-2/3',
        'mx-2/4',
        'mx-2/5',
        'mx-2/6',
        'mx-20',
        'mx-24',
        'mx-25',
        'mx-28',
        'mx-3',
        'mx-3/12',
        'mx-3/4',
        'mx-3/5',
        'mx-3/6',
        'mx-32',
        'mx-35',
        'mx-36',
        'mx-4',
        'mx-4/12',
        'mx-4/5',
        'mx-4/6',
        'mx-40',
        'mx-44',
        'mx-48',
        'mx-5',
        'mx-5/12',
        'mx-5/6',
        'mx-52',
        'mx-56',
        'mx-6',
        'mx-6/12',
        'mx-60',
        'mx-64',
        'mx-7',
        'mx-7/12',
        'mx-72',
        'mx-8',
        'mx-8/12',
        'mx-80',
        'mx-9',
        'mx-9/12',
        'mx-96',
        'mx-auto',
        'mx-full',
        'mx-px',
        'my-0',
        'my-05',
        'my-1',
        'my-1/12',
        'my-1/2',
        'my-1/3',
        'my-1/4',
        'my-1/5',
        'my-1/6',
        'my-10',
        'my-10/12',
        'my-11',
        'my-11/12',
        'my-12',
        'my-13',
        'my-14',
        'my-15',
        'my-16',
        'my-2',
        'my-2/12',
        'my-2/3',
        'my-2/4',
        'my-2/5',
        'my-2/6',
        'my-20',
        'my-24',
        'my-25',
        'my-28',
        'my-3',
        'my-3/12',
        'my-3/4',
        'my-3/5',
        'my-3/6',
        'my-32',
        'my-35',
        'my-36',
        'my-4',
        'my-4/12',
        'my-4/5',
        'my-4/6',
        'my-40',
        'my-44',
        'my-48',
        'my-5',
        'my-5/12',
        'my-5/6',
        'my-52',
        'my-56',
        'my-6',
        'my-6/12',
        'my-60',
        'my-64',
        'my-7',
        'my-7/12',
        'my-72',
        'my-8',
        'my-8/12',
        'my-80',
        'my-9',
        'my-9/12',
        'my-96',
        'my-auto',
        'my-full',
        'my-px',
        'no-underline',
        'normal-case',
        'normal-nums',
        'not-italic',
        'not-sr-only',
        'object-bottom',
        'object-center',
        'object-contain',
        'object-cover',
        'object-fill',
        'object-left',
        'object-left-bottom',
        'object-left-top',
        'object-none',
        'object-right',
        'object-right-bottom',
        'object-right-top',
        'object-scale-down',
        'object-top',
        'oldstyle-nums',
        'opacity-0',
        'opacity-10',
        'opacity-100',
        'opacity-20',
        'opacity-25',
        'opacity-30',
        'opacity-40',
        'opacity-5',
        'opacity-50',
        'opacity-60',
        'opacity-70',
        'opacity-75',
        'opacity-80',
        'opacity-90',
        'opacity-95',
        'order-1',
        'order-10',
        'order-11',
        'order-12',
        'order-2',
        'order-3',
        'order-4',
        'order-5',
        'order-6',
        'order-7',
        'order-8',
        'order-9',
        'order-first',
        'order-last',
        'order-none',
        'ordinal',
        'ordinal,',
        'origin-bottom',
        'origin-bottom-left',
        'origin-bottom-right',
        'origin-center',
        'origin-left',
        'origin-right',
        'origin-top',
        'origin-top-left',
        'origin-top-right',
        'outline-black',
        'outline-none',
        'outline-white',
        'overflow-auto',
        'overflow-clip',
        'overflow-ellipsis',
        'overflow-hidden',
        'overflow-scroll',
        'overflow-visible',
        'overflow-x-auto',
        'overflow-x-hidden',
        'overflow-x-scroll',
        'overflow-x-visible',
        'overflow-y-auto',
        'overflow-y-hidden',
        'overflow-y-scroll',
        'overflow-y-visible',
        'overscroll-auto',
        'overscroll-contain',
        'overscroll-none',
        'overscroll-x-auto',
        'overscroll-x-contain',
        'overscroll-x-none',
        'overscroll-y-auto',
        'overscroll-y-contain',
        'overscroll-y-none',
        'p-0',
        'p-05',
        'p-1',
        'p-1/12',
        'p-1/2',
        'p-1/3',
        'p-1/4',
        'p-1/5',
        'p-1/6',
        'p-10',
        'p-10/12',
        'p-11',
        'p-11/12',
        'p-12',
        'p-13',
        'p-14',
        'p-15',
        'p-16',
        'p-2',
        'p-2/12',
        'p-2/3',
        'p-2/4',
        'p-2/5',
        'p-2/6',
        'p-20',
        'p-24',
        'p-25',
        'p-28',
        'p-3',
        'p-3/12',
        'p-3/4',
        'p-3/5',
        'p-3/6',
        'p-32',
        'p-35',
        'p-36',
        'p-4',
        'p-4/12',
        'p-4/5',
        'p-4/6',
        'p-40',
        'p-44',
        'p-48',
        'p-5',
        'p-5/12',
        'p-5/6',
        'p-52',
        'p-56',
        'p-6',
        'p-6/12',
        'p-60',
        'p-64',
        'p-7',
        'p-7/12',
        'p-72',
        'p-8',
        'p-8/12',
        'p-80',
        'p-9',
        'p-9/12',
        'p-96',
        'p-full',
        'p-px',
        'pb-0',
        'pb-05',
        'pb-1',
        'pb-1/12',
        'pb-1/2',
        'pb-1/3',
        'pb-1/4',
        'pb-1/5',
        'pb-1/6',
        'pb-10',
        'pb-10/12',
        'pb-11',
        'pb-11/12',
        'pb-12',
        'pb-13',
        'pb-14',
        'pb-15',
        'pb-16',
        'pb-2',
        'pb-2/12',
        'pb-2/3',
        'pb-2/4',
        'pb-2/5',
        'pb-2/6',
        'pb-20',
        'pb-24',
        'pb-25',
        'pb-28',
        'pb-3',
        'pb-3/12',
        'pb-3/4',
        'pb-3/5',
        'pb-3/6',
        'pb-32',
        'pb-35',
        'pb-36',
        'pb-4',
        'pb-4/12',
        'pb-4/5',
        'pb-4/6',
        'pb-40',
        'pb-44',
        'pb-48',
        'pb-5',
        'pb-5/12',
        'pb-5/6',
        'pb-52',
        'pb-56',
        'pb-6',
        'pb-6/12',
        'pb-60',
        'pb-64',
        'pb-7',
        'pb-7/12',
        'pb-72',
        'pb-8',
        'pb-8/12',
        'pb-80',
        'pb-9',
        'pb-9/12',
        'pb-96',
        'pb-full',
        'pb-px',
        'pl-0',
        'pl-05',
        'pl-1',
        'pl-1/12',
        'pl-1/2',
        'pl-1/3',
        'pl-1/4',
        'pl-1/5',
        'pl-1/6',
        'pl-10',
        'pl-10/12',
        'pl-11',
        'pl-11/12',
        'pl-12',
        'pl-13',
        'pl-14',
        'pl-15',
        'pl-16',
        'pl-2',
        'pl-2/12',
        'pl-2/3',
        'pl-2/4',
        'pl-2/5',
        'pl-2/6',
        'pl-20',
        'pl-24',
        'pl-25',
        'pl-28',
        'pl-3',
        'pl-3/12',
        'pl-3/4',
        'pl-3/5',
        'pl-3/6',
        'pl-32',
        'pl-35',
        'pl-36',
        'pl-4',
        'pl-4/12',
        'pl-4/5',
        'pl-4/6',
        'pl-40',
        'pl-44',
        'pl-48',
        'pl-5',
        'pl-5/12',
        'pl-5/6',
        'pl-52',
        'pl-56',
        'pl-6',
        'pl-6/12',
        'pl-60',
        'pl-64',
        'pl-7',
        'pl-7/12',
        'pl-72',
        'pl-8',
        'pl-8/12',
        'pl-80',
        'pl-9',
        'pl-9/12',
        'pl-96',
        'pl-full',
        'pl-px',
        'place-content-around',
        'place-content-between',
        'place-content-center',
        'place-content-end',
        'place-content-evenly',
        'place-content-start',
        'place-content-stretch',
        'place-items-auto',
        'place-items-center',
        'place-items-end',
        'place-items-start',
        'place-items-stretch',
        'place-self-auto',
        'place-self-center',
        'place-self-end',
        'place-self-start',
        'place-self-stretch',
        'placeholder-black',
        'placeholder-blue-100',
        'placeholder-blue-200',
        'placeholder-blue-300',
        'placeholder-blue-400',
        'placeholder-blue-50',
        'placeholder-blue-500',
        'placeholder-blue-600',
        'placeholder-blue-700',
        'placeholder-blue-800',
        'placeholder-blue-900',
        'placeholder-cool-gray-100',
        'placeholder-cool-gray-200',
        'placeholder-cool-gray-300',
        'placeholder-cool-gray-400',
        'placeholder-cool-gray-50',
        'placeholder-cool-gray-500',
        'placeholder-cool-gray-600',
        'placeholder-cool-gray-700',
        'placeholder-cool-gray-800',
        'placeholder-cool-gray-900',
        'placeholder-current',
        'placeholder-gray-100',
        'placeholder-gray-200',
        'placeholder-gray-300',
        'placeholder-gray-400',
        'placeholder-gray-50',
        'placeholder-gray-500',
        'placeholder-gray-600',
        'placeholder-gray-700',
        'placeholder-gray-800',
        'placeholder-gray-900',
        'placeholder-green-100',
        'placeholder-green-200',
        'placeholder-green-300',
        'placeholder-green-400',
        'placeholder-green-50',
        'placeholder-green-500',
        'placeholder-green-600',
        'placeholder-green-700',
        'placeholder-green-800',
        'placeholder-green-900',
        'placeholder-indigo-100',
        'placeholder-indigo-200',
        'placeholder-indigo-300',
        'placeholder-indigo-400',
        'placeholder-indigo-50',
        'placeholder-indigo-500',
        'placeholder-indigo-600',
        'placeholder-indigo-700',
        'placeholder-indigo-800',
        'placeholder-indigo-900',
        'placeholder-opacity-0',
        'placeholder-opacity-10',
        'placeholder-opacity-100',
        'placeholder-opacity-20',
        'placeholder-opacity-25',
        'placeholder-opacity-30',
        'placeholder-opacity-40',
        'placeholder-opacity-5',
        'placeholder-opacity-50',
        'placeholder-opacity-60',
        'placeholder-opacity-70',
        'placeholder-opacity-75',
        'placeholder-opacity-80',
        'placeholder-opacity-90',
        'placeholder-opacity-95',
        'placeholder-orange-100',
        'placeholder-orange-200',
        'placeholder-orange-300',
        'placeholder-orange-400',
        'placeholder-orange-50',
        'placeholder-orange-500',
        'placeholder-orange-600',
        'placeholder-orange-700',
        'placeholder-orange-800',
        'placeholder-orange-900',
        'placeholder-pink-100',
        'placeholder-pink-200',
        'placeholder-pink-300',
        'placeholder-pink-400',
        'placeholder-pink-50',
        'placeholder-pink-500',
        'placeholder-pink-600',
        'placeholder-pink-700',
        'placeholder-pink-800',
        'placeholder-pink-900',
        'placeholder-purple-100',
        'placeholder-purple-200',
        'placeholder-purple-300',
        'placeholder-purple-400',
        'placeholder-purple-50',
        'placeholder-purple-500',
        'placeholder-purple-600',
        'placeholder-purple-700',
        'placeholder-purple-800',
        'placeholder-purple-900',
        'placeholder-red-100',
        'placeholder-red-200',
        'placeholder-red-300',
        'placeholder-red-400',
        'placeholder-red-50',
        'placeholder-red-500',
        'placeholder-red-600',
        'placeholder-red-700',
        'placeholder-red-800',
        'placeholder-red-900',
        'placeholder-teal-100',
        'placeholder-teal-200',
        'placeholder-teal-300',
        'placeholder-teal-400',
        'placeholder-teal-50',
        'placeholder-teal-500',
        'placeholder-teal-600',
        'placeholder-teal-700',
        'placeholder-teal-800',
        'placeholder-teal-900',
        'placeholder-transparent',
        'placeholder-white',
        'placeholder-yellow-100',
        'placeholder-yellow-200',
        'placeholder-yellow-300',
        'placeholder-yellow-400',
        'placeholder-yellow-50',
        'placeholder-yellow-500',
        'placeholder-yellow-600',
        'placeholder-yellow-700',
        'placeholder-yellow-800',
        'placeholder-yellow-900',
        'pointer-events-auto',
        'pointer-events-none',
        'pr-0',
        'pr-05',
        'pr-1',
        'pr-1/12',
        'pr-1/2',
        'pr-1/3',
        'pr-1/4',
        'pr-1/5',
        'pr-1/6',
        'pr-10',
        'pr-10/12',
        'pr-11',
        'pr-11/12',
        'pr-12',
        'pr-13',
        'pr-14',
        'pr-15',
        'pr-16',
        'pr-2',
        'pr-2/12',
        'pr-2/3',
        'pr-2/4',
        'pr-2/5',
        'pr-2/6',
        'pr-20',
        'pr-24',
        'pr-25',
        'pr-28',
        'pr-3',
        'pr-3/12',
        'pr-3/4',
        'pr-3/5',
        'pr-3/6',
        'pr-32',
        'pr-35',
        'pr-36',
        'pr-4',
        'pr-4/12',
        'pr-4/5',
        'pr-4/6',
        'pr-40',
        'pr-44',
        'pr-48',
        'pr-5',
        'pr-5/12',
        'pr-5/6',
        'pr-52',
        'pr-56',
        'pr-6',
        'pr-6/12',
        'pr-60',
        'pr-64',
        'pr-7',
        'pr-7/12',
        'pr-72',
        'pr-8',
        'pr-8/12',
        'pr-80',
        'pr-9',
        'pr-9/12',
        'pr-96',
        'pr-full',
        'pr-px',
        'proportional-nums',
        'prose',
        'prose-2xl',
        'prose-lg',
        'prose-sm',
        'prose-xl',
        'pt-0',
        'pt-05',
        'pt-1',
        'pt-1/12',
        'pt-1/2',
        'pt-1/3',
        'pt-1/4',
        'pt-1/5',
        'pt-1/6',
        'pt-10',
        'pt-10/12',
        'pt-11',
        'pt-11/12',
        'pt-12',
        'pt-13',
        'pt-14',
        'pt-15',
        'pt-16',
        'pt-2',
        'pt-2/12',
        'pt-2/3',
        'pt-2/4',
        'pt-2/5',
        'pt-2/6',
        'pt-20',
        'pt-24',
        'pt-25',
        'pt-28',
        'pt-3',
        'pt-3/12',
        'pt-3/4',
        'pt-3/5',
        'pt-3/6',
        'pt-32',
        'pt-35',
        'pt-36',
        'pt-4',
        'pt-4/12',
        'pt-4/5',
        'pt-4/6',
        'pt-40',
        'pt-44',
        'pt-48',
        'pt-5',
        'pt-5/12',
        'pt-5/6',
        'pt-52',
        'pt-56',
        'pt-6',
        'pt-6/12',
        'pt-60',
        'pt-64',
        'pt-7',
        'pt-7/12',
        'pt-72',
        'pt-8',
        'pt-8/12',
        'pt-80',
        'pt-9',
        'pt-9/12',
        'pt-96',
        'pt-full',
        'pt-px',
        'px-0',
        'px-05',
        'px-1',
        'px-1/12',
        'px-1/2',
        'px-1/3',
        'px-1/4',
        'px-1/5',
        'px-1/6',
        'px-10',
        'px-10/12',
        'px-11',
        'px-11/12',
        'px-12',
        'px-13',
        'px-14',
        'px-15',
        'px-16',
        'px-2',
        'px-2/12',
        'px-2/3',
        'px-2/4',
        'px-2/5',
        'px-2/6',
        'px-20',
        'px-24',
        'px-25',
        'px-28',
        'px-3',
        'px-3/12',
        'px-3/4',
        'px-3/5',
        'px-3/6',
        'px-32',
        'px-35',
        'px-36',
        'px-4',
        'px-4/12',
        'px-4/5',
        'px-4/6',
        'px-40',
        'px-44',
        'px-48',
        'px-5',
        'px-5/12',
        'px-5/6',
        'px-52',
        'px-56',
        'px-6',
        'px-6/12',
        'px-60',
        'px-64',
        'px-7',
        'px-7/12',
        'px-72',
        'px-8',
        'px-8/12',
        'px-80',
        'px-9',
        'px-9/12',
        'px-96',
        'px-full',
        'px-px',
        'py-0',
        'py-05',
        'py-1',
        'py-1/12',
        'py-1/2',
        'py-1/3',
        'py-1/4',
        'py-1/5',
        'py-1/6',
        'py-10',
        'py-10/12',
        'py-11',
        'py-11/12',
        'py-12',
        'py-13',
        'py-14',
        'py-15',
        'py-16',
        'py-2',
        'py-2/12',
        'py-2/3',
        'py-2/4',
        'py-2/5',
        'py-2/6',
        'py-20',
        'py-24',
        'py-25',
        'py-28',
        'py-3',
        'py-3/12',
        'py-3/4',
        'py-3/5',
        'py-3/6',
        'py-32',
        'py-35',
        'py-36',
        'py-4',
        'py-4/12',
        'py-4/5',
        'py-4/6',
        'py-40',
        'py-44',
        'py-48',
        'py-5',
        'py-5/12',
        'py-5/6',
        'py-52',
        'py-56',
        'py-6',
        'py-6/12',
        'py-60',
        'py-64',
        'py-7',
        'py-7/12',
        'py-72',
        'py-8',
        'py-8/12',
        'py-80',
        'py-9',
        'py-9/12',
        'py-96',
        'py-full',
        'py-px',
        'relative',
        'resize',
        'resize-none',
        'resize-x',
        'resize-y',
        'right-0',
        'right-05',
        'right-1',
        'right-1/12',
        'right-1/2',
        'right-1/3',
        'right-1/4',
        'right-1/5',
        'right-1/6',
        'right-10',
        'right-10/12',
        'right-11',
        'right-11/12',
        'right-12',
        'right-13',
        'right-14',
        'right-15',
        'right-16',
        'right-2',
        'right-2/12',
        'right-2/3',
        'right-2/4',
        'right-2/5',
        'right-2/6',
        'right-20',
        'right-24',
        'right-25',
        'right-28',
        'right-3',
        'right-3/12',
        'right-3/4',
        'right-3/5',
        'right-3/6',
        'right-32',
        'right-35',
        'right-36',
        'right-4',
        'right-4/12',
        'right-4/5',
        'right-4/6',
        'right-40',
        'right-44',
        'right-48',
        'right-5',
        'right-5/12',
        'right-5/6',
        'right-52',
        'right-56',
        'right-6',
        'right-6/12',
        'right-60',
        'right-64',
        'right-7',
        'right-7/12',
        'right-72',
        'right-8',
        'right-8/12',
        'right-80',
        'right-9',
        'right-9/12',
        'right-96',
        'right-auto',
        'right-full',
        'right-px',
        'ring',
        'ring-0',
        'ring-1',
        'ring-2',
        'ring-4',
        'ring-8',
        'ring-black',
        'ring-blue-100',
        'ring-blue-200',
        'ring-blue-300',
        'ring-blue-400',
        'ring-blue-50',
        'ring-blue-500',
        'ring-blue-600',
        'ring-blue-700',
        'ring-blue-800',
        'ring-blue-900',
        'ring-current',
        'ring-gray-100',
        'ring-gray-200',
        'ring-gray-300',
        'ring-gray-400',
        'ring-gray-50',
        'ring-gray-500',
        'ring-gray-600',
        'ring-gray-700',
        'ring-gray-800',
        'ring-gray-900',
        'ring-green-100',
        'ring-green-200',
        'ring-green-300',
        'ring-green-400',
        'ring-green-50',
        'ring-green-500',
        'ring-green-600',
        'ring-green-700',
        'ring-green-800',
        'ring-green-900',
        'ring-indigo-100',
        'ring-indigo-200',
        'ring-indigo-300',
        'ring-indigo-400',
        'ring-indigo-50',
        'ring-indigo-500',
        'ring-indigo-600',
        'ring-indigo-700',
        'ring-indigo-800',
        'ring-indigo-900',
        'ring-inset',
        'ring-offset-0',
        'ring-offset-1',
        'ring-offset-2',
        'ring-offset-4',
        'ring-offset-8',
        'ring-offset-black',
        'ring-offset-blue-100',
        'ring-offset-blue-200',
        'ring-offset-blue-300',
        'ring-offset-blue-400',
        'ring-offset-blue-50',
        'ring-offset-blue-500',
        'ring-offset-blue-600',
        'ring-offset-blue-700',
        'ring-offset-blue-800',
        'ring-offset-blue-900',
        'ring-offset-current',
        'ring-offset-gray-100',
        'ring-offset-gray-200',
        'ring-offset-gray-300',
        'ring-offset-gray-400',
        'ring-offset-gray-50',
        'ring-offset-gray-500',
        'ring-offset-gray-600',
        'ring-offset-gray-700',
        'ring-offset-gray-800',
        'ring-offset-gray-900',
        'ring-offset-green-100',
        'ring-offset-green-200',
        'ring-offset-green-300',
        'ring-offset-green-400',
        'ring-offset-green-50',
        'ring-offset-green-500',
        'ring-offset-green-600',
        'ring-offset-green-700',
        'ring-offset-green-800',
        'ring-offset-green-900',
        'ring-offset-indigo-100',
        'ring-offset-indigo-200',
        'ring-offset-indigo-300',
        'ring-offset-indigo-400',
        'ring-offset-indigo-50',
        'ring-offset-indigo-500',
        'ring-offset-indigo-600',
        'ring-offset-indigo-700',
        'ring-offset-indigo-800',
        'ring-offset-indigo-900',
        'ring-offset-pink-100',
        'ring-offset-pink-200',
        'ring-offset-pink-300',
        'ring-offset-pink-400',
        'ring-offset-pink-50',
        'ring-offset-pink-500',
        'ring-offset-pink-600',
        'ring-offset-pink-700',
        'ring-offset-pink-800',
        'ring-offset-pink-900',
        'ring-offset-purple-100',
        'ring-offset-purple-200',
        'ring-offset-purple-300',
        'ring-offset-purple-400',
        'ring-offset-purple-50',
        'ring-offset-purple-500',
        'ring-offset-purple-600',
        'ring-offset-purple-700',
        'ring-offset-purple-800',
        'ring-offset-purple-900',
        'ring-offset-red-100',
        'ring-offset-red-200',
        'ring-offset-red-300',
        'ring-offset-red-400',
        'ring-offset-red-50',
        'ring-offset-red-500',
        'ring-offset-red-600',
        'ring-offset-red-700',
        'ring-offset-red-800',
        'ring-offset-red-900',
        'ring-offset-transparent',
        'ring-offset-white',
        'ring-offset-yellow-100',
        'ring-offset-yellow-200',
        'ring-offset-yellow-300',
        'ring-offset-yellow-400',
        'ring-offset-yellow-50',
        'ring-offset-yellow-500',
        'ring-offset-yellow-600',
        'ring-offset-yellow-700',
        'ring-offset-yellow-800',
        'ring-offset-yellow-900',
        'ring-opacity-0',
        'ring-opacity-10',
        'ring-opacity-100',
        'ring-opacity-20',
        'ring-opacity-25',
        'ring-opacity-30',
        'ring-opacity-40',
        'ring-opacity-5',
        'ring-opacity-50',
        'ring-opacity-60',
        'ring-opacity-70',
        'ring-opacity-75',
        'ring-opacity-80',
        'ring-opacity-90',
        'ring-opacity-95',
        'ring-pink-100',
        'ring-pink-200',
        'ring-pink-300',
        'ring-pink-400',
        'ring-pink-50',
        'ring-pink-500',
        'ring-pink-600',
        'ring-pink-700',
        'ring-pink-800',
        'ring-pink-900',
        'ring-purple-100',
        'ring-purple-200',
        'ring-purple-300',
        'ring-purple-400',
        'ring-purple-50',
        'ring-purple-500',
        'ring-purple-600',
        'ring-purple-700',
        'ring-purple-800',
        'ring-purple-900',
        'ring-red-100',
        'ring-red-200',
        'ring-red-300',
        'ring-red-400',
        'ring-red-50',
        'ring-red-500',
        'ring-red-600',
        'ring-red-700',
        'ring-red-800',
        'ring-red-900',
        'ring-transparent',
        'ring-white',
        'ring-yellow-100',
        'ring-yellow-200',
        'ring-yellow-300',
        'ring-yellow-400',
        'ring-yellow-50',
        'ring-yellow-500',
        'ring-yellow-600',
        'ring-yellow-700',
        'ring-yellow-800',
        'ring-yellow-900',
        'rotate-0',
        'rotate-1',
        'rotate-12',
        'rotate-180',
        'rotate-2',
        'rotate-3',
        'rotate-45',
        'rotate-6',
        'rotate-90',
        'rounded',
        'rounded-2xl',
        'rounded-3xl',
        'rounded-b',
        'rounded-b-2xl',
        'rounded-b-3xl',
        'rounded-b-full',
        'rounded-b-lg',
        'rounded-b-md',
        'rounded-b-none',
        'rounded-b-sm',
        'rounded-b-xl',
        'rounded-bl',
        'rounded-bl-2xl',
        'rounded-bl-3xl',
        'rounded-bl-full',
        'rounded-bl-lg',
        'rounded-bl-md',
        'rounded-bl-none',
        'rounded-bl-sm',
        'rounded-bl-xl',
        'rounded-br',
        'rounded-br-2xl',
        'rounded-br-3xl',
        'rounded-br-full',
        'rounded-br-lg',
        'rounded-br-md',
        'rounded-br-none',
        'rounded-br-sm',
        'rounded-br-xl',
        'rounded-full',
        'rounded-l',
        'rounded-l-2xl',
        'rounded-l-3xl',
        'rounded-l-full',
        'rounded-l-lg',
        'rounded-l-md',
        'rounded-l-none',
        'rounded-l-sm',
        'rounded-l-xl',
        'rounded-lg',
        'rounded-md',
        'rounded-none',
        'rounded-r',
        'rounded-r-2xl',
        'rounded-r-3xl',
        'rounded-r-full',
        'rounded-r-lg',
        'rounded-r-md',
        'rounded-r-none',
        'rounded-r-sm',
        'rounded-r-xl',
        'rounded-sm',
        'rounded-t',
        'rounded-t-2xl',
        'rounded-t-3xl',
        'rounded-t-full',
        'rounded-t-lg',
        'rounded-t-md',
        'rounded-t-none',
        'rounded-t-sm',
        'rounded-t-xl',
        'rounded-tl',
        'rounded-tl-2xl',
        'rounded-tl-3xl',
        'rounded-tl-full',
        'rounded-tl-lg',
        'rounded-tl-md',
        'rounded-tl-none',
        'rounded-tl-sm',
        'rounded-tl-xl',
        'rounded-tr',
        'rounded-tr-2xl',
        'rounded-tr-3xl',
        'rounded-tr-full',
        'rounded-tr-lg',
        'rounded-tr-md',
        'rounded-tr-none',
        'rounded-tr-sm',
        'rounded-tr-xl',
        'rounded-xl',
        'row-auto',
        'row-end-1',
        'row-end-2',
        'row-end-3',
        'row-end-4',
        'row-end-5',
        'row-end-6',
        'row-end-7',
        'row-end-auto',
        'row-gap-0',
        'row-gap-05',
        'row-gap-1',
        'row-gap-1/12',
        'row-gap-1/2',
        'row-gap-1/3',
        'row-gap-1/4',
        'row-gap-1/5',
        'row-gap-1/6',
        'row-gap-10',
        'row-gap-10/12',
        'row-gap-11',
        'row-gap-11/12',
        'row-gap-12',
        'row-gap-13',
        'row-gap-14',
        'row-gap-15',
        'row-gap-16',
        'row-gap-2',
        'row-gap-2/12',
        'row-gap-2/3',
        'row-gap-2/4',
        'row-gap-2/5',
        'row-gap-2/6',
        'row-gap-20',
        'row-gap-24',
        'row-gap-25',
        'row-gap-28',
        'row-gap-3',
        'row-gap-3/12',
        'row-gap-3/4',
        'row-gap-3/5',
        'row-gap-3/6',
        'row-gap-32',
        'row-gap-35',
        'row-gap-36',
        'row-gap-4',
        'row-gap-4/12',
        'row-gap-4/5',
        'row-gap-4/6',
        'row-gap-40',
        'row-gap-44',
        'row-gap-48',
        'row-gap-5',
        'row-gap-5/12',
        'row-gap-5/6',
        'row-gap-52',
        'row-gap-56',
        'row-gap-6',
        'row-gap-6/12',
        'row-gap-60',
        'row-gap-64',
        'row-gap-7',
        'row-gap-7/12',
        'row-gap-72',
        'row-gap-8',
        'row-gap-8/12',
        'row-gap-80',
        'row-gap-9',
        'row-gap-9/12',
        'row-gap-96',
        'row-gap-full',
        'row-gap-px',
        'row-span-1',
        'row-span-2',
        'row-span-3',
        'row-span-4',
        'row-span-5',
        'row-span-6',
        'row-span-full',
        'row-start-1',
        'row-start-2',
        'row-start-3',
        'row-start-4',
        'row-start-5',
        'row-start-6',
        'row-start-7',
        'row-start-auto',
        'scale-0',
        'scale-100',
        'scale-105',
        'scale-110',
        'scale-125',
        'scale-150',
        'scale-50',
        'scale-75',
        'scale-90',
        'scale-95',
        'scale-x-0',
        'scale-x-100',
        'scale-x-105',
        'scale-x-110',
        'scale-x-125',
        'scale-x-150',
        'scale-x-50',
        'scale-x-75',
        'scale-x-90',
        'scale-x-95',
        'scale-y-0',
        'scale-y-100',
        'scale-y-105',
        'scale-y-110',
        'scale-y-125',
        'scale-y-150',
        'scale-y-50',
        'scale-y-75',
        'scale-y-90',
        'scale-y-95',
        'scrolling-auto',
        'scrolling-touch',
        'select-all',
        'select-auto',
        'select-none',
        'select-text',
        'self-auto',
        'self-center',
        'self-end',
        'self-start',
        'self-stretch',
        'shadow',
        'shadow-2xl',
        'shadow-inner',
        'shadow-lg',
        'shadow-md',
        'shadow-none',
        'shadow-outline',
        'shadow-outline-blue',
        'shadow-outline-gray',
        'shadow-outline-green',
        'shadow-outline-indigo',
        'shadow-outline-orange',
        'shadow-outline-pink',
        'shadow-outline-purple',
        'shadow-outline-red',
        'shadow-outline-teal',
        'shadow-outline-yellow',
        'shadow-sm',
        'shadow-solid',
        'shadow-xl',
        'shadow-xs',
        'skew-x-0',
        'skew-x-1',
        'skew-x-12',
        'skew-x-2',
        'skew-x-3',
        'skew-x-6',
        'skew-y-0',
        'skew-y-1',
        'skew-y-12',
        'skew-y-2',
        'skew-y-3',
        'skew-y-6',
        'slashed-zero',
        'space-x-0',
        'space-x-05',
        'space-x-1',
        'space-x-1/12',
        'space-x-1/2',
        'space-x-1/3',
        'space-x-1/4',
        'space-x-1/5',
        'space-x-1/6',
        'space-x-10',
        'space-x-10/12',
        'space-x-11',
        'space-x-11/12',
        'space-x-12',
        'space-x-13',
        'space-x-14',
        'space-x-15',
        'space-x-16',
        'space-x-2',
        'space-x-2/12',
        'space-x-2/3',
        'space-x-2/4',
        'space-x-2/5',
        'space-x-2/6',
        'space-x-20',
        'space-x-24',
        'space-x-25',
        'space-x-28',
        'space-x-3',
        'space-x-3/12',
        'space-x-3/4',
        'space-x-3/5',
        'space-x-3/6',
        'space-x-32',
        'space-x-35',
        'space-x-36',
        'space-x-4',
        'space-x-4/12',
        'space-x-4/5',
        'space-x-4/6',
        'space-x-40',
        'space-x-44',
        'space-x-48',
        'space-x-5',
        'space-x-5/12',
        'space-x-5/6',
        'space-x-52',
        'space-x-56',
        'space-x-6',
        'space-x-6/12',
        'space-x-60',
        'space-x-64',
        'space-x-7',
        'space-x-7/12',
        'space-x-72',
        'space-x-8',
        'space-x-8/12',
        'space-x-80',
        'space-x-9',
        'space-x-9/12',
        'space-x-96',
        'space-x-full',
        'space-x-px',
        'space-x-reverse',
        'space-y-0',
        'space-y-05',
        'space-y-1',
        'space-y-1/12',
        'space-y-1/2',
        'space-y-1/3',
        'space-y-1/4',
        'space-y-1/5',
        'space-y-1/6',
        'space-y-10',
        'space-y-10/12',
        'space-y-11',
        'space-y-11/12',
        'space-y-12',
        'space-y-13',
        'space-y-14',
        'space-y-15',
        'space-y-16',
        'space-y-2',
        'space-y-2/12',
        'space-y-2/3',
        'space-y-2/4',
        'space-y-2/5',
        'space-y-2/6',
        'space-y-20',
        'space-y-24',
        'space-y-25',
        'space-y-28',
        'space-y-3',
        'space-y-3/12',
        'space-y-3/4',
        'space-y-3/5',
        'space-y-3/6',
        'space-y-32',
        'space-y-35',
        'space-y-36',
        'space-y-4',
        'space-y-4/12',
        'space-y-4/5',
        'space-y-4/6',
        'space-y-40',
        'space-y-44',
        'space-y-48',
        'space-y-5',
        'space-y-5/12',
        'space-y-5/6',
        'space-y-52',
        'space-y-56',
        'space-y-6',
        'space-y-6/12',
        'space-y-60',
        'space-y-64',
        'space-y-7',
        'space-y-7/12',
        'space-y-72',
        'space-y-8',
        'space-y-8/12',
        'space-y-80',
        'space-y-9',
        'space-y-9/12',
        'space-y-96',
        'space-y-full',
        'space-y-px',
        'space-y-reverse',
        'sr-only',
        'stacked-fractions',
        'static',
        'sticky',
        'stroke-0',
        'stroke-1',
        'stroke-2',
        'stroke-current',
        'subpixel-antialiased',
        'table',
        'table-auto',
        'table-caption',
        'table-cell',
        'table-column',
        'table-column-group',
        'table-fixed',
        'table-footer-group',
        'table-header-group',
        'table-row',
        'table-row-group',
        'tabular-nums',
        'text-2xl',
        'text-3xl',
        'text-4xl',
        'text-5xl',
        'text-6xl',
        'text-7xl',
        'text-8xl',
        'text-9xl',
        'text-base',
        'text-black',
        'text-blue-100',
        'text-blue-200',
        'text-blue-300',
        'text-blue-400',
        'text-blue-50',
        'text-blue-500',
        'text-blue-600',
        'text-blue-700',
        'text-blue-800',
        'text-blue-900',
        'text-center',
        'text-cool-gray-100',
        'text-cool-gray-200',
        'text-cool-gray-300',
        'text-cool-gray-400',
        'text-cool-gray-50',
        'text-cool-gray-500',
        'text-cool-gray-600',
        'text-cool-gray-700',
        'text-cool-gray-800',
        'text-cool-gray-900',
        'text-current',
        'text-gray-100',
        'text-gray-200',
        'text-gray-300',
        'text-gray-400',
        'text-gray-50',
        'text-gray-500',
        'text-gray-600',
        'text-gray-700',
        'text-gray-800',
        'text-gray-900',
        'text-green-100',
        'text-green-200',
        'text-green-300',
        'text-green-400',
        'text-green-50',
        'text-green-500',
        'text-green-600',
        'text-green-700',
        'text-green-800',
        'text-green-900',
        'text-indigo-100',
        'text-indigo-200',
        'text-indigo-300',
        'text-indigo-400',
        'text-indigo-50',
        'text-indigo-500',
        'text-indigo-600',
        'text-indigo-700',
        'text-indigo-800',
        'text-indigo-900',
        'text-justify',
        'text-left',
        'text-lg',
        'text-opacity-0',
        'text-opacity-10',
        'text-opacity-100',
        'text-opacity-20',
        'text-opacity-25',
        'text-opacity-30',
        'text-opacity-40',
        'text-opacity-5',
        'text-opacity-50',
        'text-opacity-60',
        'text-opacity-70',
        'text-opacity-75',
        'text-opacity-80',
        'text-opacity-90',
        'text-opacity-95',
        'text-orange-100',
        'text-orange-200',
        'text-orange-300',
        'text-orange-400',
        'text-orange-50',
        'text-orange-500',
        'text-orange-600',
        'text-orange-700',
        'text-orange-800',
        'text-orange-900',
        'text-pink-100',
        'text-pink-200',
        'text-pink-300',
        'text-pink-400',
        'text-pink-50',
        'text-pink-500',
        'text-pink-600',
        'text-pink-700',
        'text-pink-800',
        'text-pink-900',
        'text-purple-100',
        'text-purple-200',
        'text-purple-300',
        'text-purple-400',
        'text-purple-50',
        'text-purple-500',
        'text-purple-600',
        'text-purple-700',
        'text-purple-800',
        'text-purple-900',
        'text-red-100',
        'text-red-200',
        'text-red-300',
        'text-red-400',
        'text-red-50',
        'text-red-500',
        'text-red-600',
        'text-red-700',
        'text-red-800',
        'text-red-900',
        'text-right',
        'text-sm',
        'text-teal-100',
        'text-teal-200',
        'text-teal-300',
        'text-teal-400',
        'text-teal-50',
        'text-teal-500',
        'text-teal-600',
        'text-teal-700',
        'text-teal-800',
        'text-teal-900',
        'text-transparent',
        'text-white',
        'text-xl',
        'text-xs',
        'text-yellow-100',
        'text-yellow-200',
        'text-yellow-300',
        'text-yellow-400',
        'text-yellow-50',
        'text-yellow-500',
        'text-yellow-600',
        'text-yellow-700',
        'text-yellow-800',
        'text-yellow-900',
        'to-black',
        'to-blue-100',
        'to-blue-200',
        'to-blue-300',
        'to-blue-400',
        'to-blue-50',
        'to-blue-500',
        'to-blue-600',
        'to-blue-700',
        'to-blue-800',
        'to-blue-900',
        'to-cool-gray-100',
        'to-cool-gray-200',
        'to-cool-gray-300',
        'to-cool-gray-400',
        'to-cool-gray-50',
        'to-cool-gray-500',
        'to-cool-gray-600',
        'to-cool-gray-700',
        'to-cool-gray-800',
        'to-cool-gray-900',
        'to-current',
        'to-gray-100',
        'to-gray-200',
        'to-gray-300',
        'to-gray-400',
        'to-gray-50',
        'to-gray-500',
        'to-gray-600',
        'to-gray-700',
        'to-gray-800',
        'to-gray-900',
        'to-green-100',
        'to-green-200',
        'to-green-300',
        'to-green-400',
        'to-green-50',
        'to-green-500',
        'to-green-600',
        'to-green-700',
        'to-green-800',
        'to-green-900',
        'to-indigo-100',
        'to-indigo-200',
        'to-indigo-300',
        'to-indigo-400',
        'to-indigo-50',
        'to-indigo-500',
        'to-indigo-600',
        'to-indigo-700',
        'to-indigo-800',
        'to-indigo-900',
        'to-orange-100',
        'to-orange-200',
        'to-orange-300',
        'to-orange-400',
        'to-orange-50',
        'to-orange-500',
        'to-orange-600',
        'to-orange-700',
        'to-orange-800',
        'to-orange-900',
        'to-pink-100',
        'to-pink-200',
        'to-pink-300',
        'to-pink-400',
        'to-pink-50',
        'to-pink-500',
        'to-pink-600',
        'to-pink-700',
        'to-pink-800',
        'to-pink-900',
        'to-purple-100',
        'to-purple-200',
        'to-purple-300',
        'to-purple-400',
        'to-purple-50',
        'to-purple-500',
        'to-purple-600',
        'to-purple-700',
        'to-purple-800',
        'to-purple-900',
        'to-red-100',
        'to-red-200',
        'to-red-300',
        'to-red-400',
        'to-red-50',
        'to-red-500',
        'to-red-600',
        'to-red-700',
        'to-red-800',
        'to-red-900',
        'to-teal-100',
        'to-teal-200',
        'to-teal-300',
        'to-teal-400',
        'to-teal-50',
        'to-teal-500',
        'to-teal-600',
        'to-teal-700',
        'to-teal-800',
        'to-teal-900',
        'to-transparent',
        'to-white',
        'to-yellow-100',
        'to-yellow-200',
        'to-yellow-300',
        'to-yellow-400',
        'to-yellow-50',
        'to-yellow-500',
        'to-yellow-600',
        'to-yellow-700',
        'to-yellow-800',
        'to-yellow-900',
        'top-0',
        'top-05',
        'top-1',
        'top-1/12',
        'top-1/2',
        'top-1/3',
        'top-1/4',
        'top-1/5',
        'top-1/6',
        'top-10',
        'top-10/12',
        'top-11',
        'top-11/12',
        'top-12',
        'top-13',
        'top-14',
        'top-15',
        'top-16',
        'top-2',
        'top-2/12',
        'top-2/3',
        'top-2/4',
        'top-2/5',
        'top-2/6',
        'top-20',
        'top-24',
        'top-25',
        'top-28',
        'top-3',
        'top-3/12',
        'top-3/4',
        'top-3/5',
        'top-3/6',
        'top-32',
        'top-35',
        'top-36',
        'top-4',
        'top-4/12',
        'top-4/5',
        'top-4/6',
        'top-40',
        'top-44',
        'top-48',
        'top-5',
        'top-5/12',
        'top-5/6',
        'top-52',
        'top-56',
        'top-6',
        'top-6/12',
        'top-60',
        'top-64',
        'top-7',
        'top-7/12',
        'top-72',
        'top-8',
        'top-8/12',
        'top-80',
        'top-9',
        'top-9/12',
        'top-96',
        'top-auto',
        'top-full',
        'top-px',
        'tracking-normal',
        'tracking-tight',
        'tracking-tighter',
        'tracking-wide',
        'tracking-wider',
        'tracking-widest',
        'transform',
        'transform-gpu',
        'transform-none',
        'transition',
        'transition-all',
        'transition-colors',
        'transition-none',
        'transition-opacity',
        'transition-shadow',
        'transition-transform',
        'translate-x-0',
        'translate-x-05',
        'translate-x-1',
        'translate-x-1/12',
        'translate-x-1/2',
        'translate-x-1/3',
        'translate-x-1/4',
        'translate-x-1/5',
        'translate-x-1/6',
        'translate-x-10',
        'translate-x-10/12',
        'translate-x-11',
        'translate-x-11/12',
        'translate-x-12',
        'translate-x-13',
        'translate-x-14',
        'translate-x-15',
        'translate-x-16',
        'translate-x-2',
        'translate-x-2/12',
        'translate-x-2/3',
        'translate-x-2/4',
        'translate-x-2/5',
        'translate-x-2/6',
        'translate-x-20',
        'translate-x-24',
        'translate-x-25',
        'translate-x-28',
        'translate-x-3',
        'translate-x-3/12',
        'translate-x-3/4',
        'translate-x-3/5',
        'translate-x-3/6',
        'translate-x-32',
        'translate-x-35',
        'translate-x-36',
        'translate-x-4',
        'translate-x-4/12',
        'translate-x-4/5',
        'translate-x-4/6',
        'translate-x-40',
        'translate-x-44',
        'translate-x-48',
        'translate-x-5',
        'translate-x-5/12',
        'translate-x-5/6',
        'translate-x-52',
        'translate-x-56',
        'translate-x-6',
        'translate-x-6/12',
        'translate-x-60',
        'translate-x-64',
        'translate-x-7',
        'translate-x-7/12',
        'translate-x-72',
        'translate-x-8',
        'translate-x-8/12',
        'translate-x-80',
        'translate-x-9',
        'translate-x-9/12',
        'translate-x-96',
        'translate-x-full',
        'translate-x-px',
        'translate-y-0',
        'translate-y-05',
        'translate-y-1',
        'translate-y-1/12',
        'translate-y-1/2',
        'translate-y-1/3',
        'translate-y-1/4',
        'translate-y-1/5',
        'translate-y-1/6',
        'translate-y-10',
        'translate-y-10/12',
        'translate-y-11',
        'translate-y-11/12',
        'translate-y-12',
        'translate-y-13',
        'translate-y-14',
        'translate-y-15',
        'translate-y-16',
        'translate-y-2',
        'translate-y-2/12',
        'translate-y-2/3',
        'translate-y-2/4',
        'translate-y-2/5',
        'translate-y-2/6',
        'translate-y-20',
        'translate-y-24',
        'translate-y-25',
        'translate-y-28',
        'translate-y-3',
        'translate-y-3/12',
        'translate-y-3/4',
        'translate-y-3/5',
        'translate-y-3/6',
        'translate-y-32',
        'translate-y-35',
        'translate-y-36',
        'translate-y-4',
        'translate-y-4/12',
        'translate-y-4/5',
        'translate-y-4/6',
        'translate-y-40',
        'translate-y-44',
        'translate-y-48',
        'translate-y-5',
        'translate-y-5/12',
        'translate-y-5/6',
        'translate-y-52',
        'translate-y-56',
        'translate-y-6',
        'translate-y-6/12',
        'translate-y-60',
        'translate-y-64',
        'translate-y-7',
        'translate-y-7/12',
        'translate-y-72',
        'translate-y-8',
        'translate-y-8/12',
        'translate-y-80',
        'translate-y-9',
        'translate-y-9/12',
        'translate-y-96',
        'translate-y-full',
        'translate-y-px',
        'truncate',
        'underline',
        'uppercase',
        'via-black',
        'via-blue-100',
        'via-blue-200',
        'via-blue-300',
        'via-blue-400',
        'via-blue-50',
        'via-blue-500',
        'via-blue-600',
        'via-blue-700',
        'via-blue-800',
        'via-blue-900',
        'via-cool-gray-100',
        'via-cool-gray-200',
        'via-cool-gray-300',
        'via-cool-gray-400',
        'via-cool-gray-50',
        'via-cool-gray-500',
        'via-cool-gray-600',
        'via-cool-gray-700',
        'via-cool-gray-800',
        'via-cool-gray-900',
        'via-current',
        'via-gray-100',
        'via-gray-200',
        'via-gray-300',
        'via-gray-400',
        'via-gray-50',
        'via-gray-500',
        'via-gray-600',
        'via-gray-700',
        'via-gray-800',
        'via-gray-900',
        'via-green-100',
        'via-green-200',
        'via-green-300',
        'via-green-400',
        'via-green-50',
        'via-green-500',
        'via-green-600',
        'via-green-700',
        'via-green-800',
        'via-green-900',
        'via-indigo-100',
        'via-indigo-200',
        'via-indigo-300',
        'via-indigo-400',
        'via-indigo-50',
        'via-indigo-500',
        'via-indigo-600',
        'via-indigo-700',
        'via-indigo-800',
        'via-indigo-900',
        'via-orange-100',
        'via-orange-200',
        'via-orange-300',
        'via-orange-400',
        'via-orange-50',
        'via-orange-500',
        'via-orange-600',
        'via-orange-700',
        'via-orange-800',
        'via-orange-900',
        'via-pink-100',
        'via-pink-200',
        'via-pink-300',
        'via-pink-400',
        'via-pink-50',
        'via-pink-500',
        'via-pink-600',
        'via-pink-700',
        'via-pink-800',
        'via-pink-900',
        'via-purple-100',
        'via-purple-200',
        'via-purple-300',
        'via-purple-400',
        'via-purple-50',
        'via-purple-500',
        'via-purple-600',
        'via-purple-700',
        'via-purple-800',
        'via-purple-900',
        'via-red-100',
        'via-red-200',
        'via-red-300',
        'via-red-400',
        'via-red-50',
        'via-red-500',
        'via-red-600',
        'via-red-700',
        'via-red-800',
        'via-red-900',
        'via-teal-100',
        'via-teal-200',
        'via-teal-300',
        'via-teal-400',
        'via-teal-50',
        'via-teal-500',
        'via-teal-600',
        'via-teal-700',
        'via-teal-800',
        'via-teal-900',
        'via-transparent',
        'via-white',
        'via-yellow-100',
        'via-yellow-200',
        'via-yellow-300',
        'via-yellow-400',
        'via-yellow-50',
        'via-yellow-500',
        'via-yellow-600',
        'via-yellow-700',
        'via-yellow-800',
        'via-yellow-900',
        'visible',
        'w-0',
        'w-05',
        'w-1',
        'w-1/12',
        'w-1/2',
        'w-1/3',
        'w-1/4',
        'w-1/5',
        'w-1/6',
        'w-10',
        'w-10/12',
        'w-11',
        'w-11/12',
        'w-12',
        'w-13',
        'w-14',
        'w-15',
        'w-16',
        'w-2',
        'w-2/12',
        'w-2/3',
        'w-2/4',
        'w-2/5',
        'w-2/6',
        'w-20',
        'w-24',
        'w-25',
        'w-28',
        'w-3',
        'w-3/12',
        'w-3/4',
        'w-3/5',
        'w-3/6',
        'w-32',
        'w-35',
        'w-36',
        'w-4',
        'w-4/12',
        'w-4/5',
        'w-4/6',
        'w-40',
        'w-44',
        'w-48',
        'w-5',
        'w-5/12',
        'w-5/6',
        'w-52',
        'w-56',
        'w-6',
        'w-6/12',
        'w-60',
        'w-64',
        'w-7',
        'w-7/12',
        'w-72',
        'w-8',
        'w-8/12',
        'w-80',
        'w-9',
        'w-9/12',
        'w-96',
        'w-auto',
        'w-full',
        'w-max',
        'w-max-content',
        'w-min',
        'w-min-content',
        'w-px',
        'w-screen',
        'whitespace-no-wrap',
        'whitespace-normal',
        'whitespace-nowrap',
        'whitespace-pre',
        'whitespace-pre-line',
        'whitespace-pre-wrap',
        'z-0',
        'z-10',
        'z-20',
        'z-30',
        'z-40',
        'z-50',
        'z-auto',
      ],
    };
  },
};
